import request, { HTTP_METHOD } from '@/utils/request';

export const fetchResources = async (
	payload: IFetchResourcesReq
): Promise<TPagination<IResource>> => {
	return request<FetchData<TPagination<IResource>>>(HTTP_METHOD.GET, '/resource', {
		query: payload
	});
};

export const fetchResourceTags = async (payload: {
	isPublic?: boolean;
	programId?: string;
	fileType?: string;
	keyword?: string;
}): Promise<IResourceTypes[]> => {
	return request<FetchData<IResourceTypes[]>>(HTTP_METHOD.GET, '/resource/tags', {
		query: payload
	});
};

export const fetchRegistrationResourcesById = (
	resourceId: string
): Promise<FetchData<IResource[]>> => {
	return request<FetchData<IResource[]>>(HTTP_METHOD.GET, `/resource/${resourceId}/download`, {
		cache: 'no-cache'
	});
};
export default {};
