'use client';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import ThemeRegistry from './ThemeRegistry';
import StyledComponentsRegistry from '@/styles/registry';

const Providers = ({ children }: { children: React.ReactNode }) => {
	return (
		<StyledComponentsRegistry>
			<ThemeRegistry options={{ key: 'mui', prepend: true }}>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-cn">
					{children}
				</LocalizationProvider>
			</ThemeRegistry>
		</StyledComponentsRegistry>
	);
};

export default Providers;
