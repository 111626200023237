import { css } from 'styled-components';

import { color as colorConfig } from './variables';
import IDotPoint, { IShadowBox, ITextLeftDecoration } from '@/interfaces/mixin';

const { primaryColor, shadowColor, textSecondaryColor, bgColor, primaryPurple, blackColor } =
	colorConfig;

const DotPoint = ({
	height,
	radius = 3,
	color = primaryColor,
	marginRight = '10px'
}: IDotPoint) => css`
	&::before {
		background-color: ${color};
		border-radius: ${radius}px;
		content: '';
		display: inline-flex;
		height: ${height};
		margin-right: ${marginRight};
		vertical-align: middle;
		width: ${radius * 2}px;
	}
`;

export const textEllipsis = (line = 1) => css`
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${line};
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const textLeftDecoration = ({
	paddingLeft = '12px',
	width = '4px',
	height = '12px',
	decorationColor = '#ff8080',
	top
}: ITextLeftDecoration = {}) => css`
	padding-left: ${paddingLeft};
	position: relative;
	&::before {
		background-color: ${decorationColor};
		border-radius: calc(${width} / 2);
		content: '';
		height: ${height};
		left: 0;
		position: absolute;
		top: ${top || '50%'};
		transform: ${top ? null : 'translateY(-50%)'};
		width: ${width};
	}
`;

export const shadowBox = ({ blurRadius = '20px', color = shadowColor }: IShadowBox = {}) => css`
	box-shadow: 0 0 ${blurRadius} 0 ${color};
`;

export const pageContainer = (maxWidth = '1440px') => css`
	margin: 0 auto;
	max-width: ${maxWidth};
`;

export const homeSectionTitle = css`
	font-family: 'LEMONMILK', sans-serif;
	font-size: 26px;
	margin: 0;
`;

export const homeSectionDescription = css`
	align-items: center;
	display: flex;
	justify-content: center;
	p {
		color: ${textSecondaryColor};
		font-size: 14px;
		margin: 24px 24px 0 24px;
		max-width: 820px;
		text-align: center;
		width: 100%;
	}
`;

export const boxHoverAnimation = (offset = 6, color = '#000') => css`
	cursor: pointer;
	&:hover {
		box-shadow: ${offset}px ${offset}px 0 ${color};
		transform: translate(-${offset / 2}px, -${offset / 2}px);
		transition: box-shadow 200ms ease-in-out, transform 200ms ease-in-out;
	}
`;

export const boxBorder = (width = '1px', radius = '2px') => css`
	border: ${width} solid #000;
	border-radius: ${radius};
`;

export const boxElevation = (elevation = 3, color = bgColor) => css`
	box-shadow: ${elevation}px ${elevation}px ${color}, ${elevation}px ${elevation}px 0 1px #000;
`;

export const dotBox = css`
	background-image: radial-gradient(circle, black 1px, transparent 1px);
	background-size: 15px 15px;
	content: '';
	height: 100%;
	padding: 35px;
	width: 100%;
`;

export const stripeBg = (width = '100%', height = '100%', bottom = '-7px', left = '-8px') => css`
	background-image: url('/icon/stripe-bg.svg');
	background-position: left bottom;
	background-repeat: repeat;
	bottom: ${bottom};
	content: '';
	height: ${height};
	left: ${left};
	position: absolute;
	width: ${width};
`;

export const offsetBackground = (
	width = '100%',
	height = '100%',
	bottom = '-8px',
	right = '-8px',
	backgroundColor = primaryPurple
) => css`
	background-color: ${backgroundColor};
	border: solid 1px ${blackColor};
	border-radius: 4px;
	bottom: ${bottom};
	content: '';
	height: ${height};
	position: absolute;
	right: ${right};
	width: ${width};
`;

export const scrollBarStyle = css`
	/* 整个滚动条 */
	::-webkit-scrollbar {
		width: 8px; /* 垂直滚动条宽度 */
		height: 8px; /* 水平滚动条高度 */
	}

	/* 滚动条轨道 */
	::-webkit-scrollbar-track {
		background-color: #f0f0f0; /* 轨道背景颜色 */
		border-radius: 4px; /* 轨道圆角 */
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		background-color: ${colorConfig.buttonDefault}; /* 滑块颜色 */
		border-radius: 4px; /* 滑块圆角 */
	}

	/* 滚动条滑块悬停效果 */
	::-webkit-scrollbar-thumb:hover {
		background-color: ${colorConfig.buttonDefaultHover}; /* 悬停时滑块颜色 */
	}

	/* 滚动条角落 */
	::-webkit-scrollbar-corner {
		background-color: #f0f0f0; /* 角落背景颜色 */
	}
`;

export { DotPoint as default };
