import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

import { EDIT_TYPE } from '@/constants/common';
import { EInformationType } from '@/constants/information';
import { MESSAGE_POST_STEP, MESSAGE_TYPE, MESSAGE_VISIBILITY } from '@/constants/message';
import {
	IFetchLikeCountByItemIdListPayload,
	IFetchLikeCountItemsListResponse,
	ILikeCountItem
} from '@/interfaces/fetch/userPreference.fetch';
import { fetchMessages } from '@/services/message';
import { fetchLikeCountByItemIdList } from '@/services/userPreference';

export const MESSAGE_LIST_MAX = 20;

type MESSAGE_TYPE_WITH_ALL = MESSAGE_TYPE | '';
/**
 * key 为 messageId
 * value 为点赞数
 */

export interface FeedState {
	messages: IFetchMessage[];
	messageLikeCountMap: Record<string, number>;
	/** 是否loading中 */
	loading: boolean;
	/** 是否加载了所有 */
	loadAll: boolean;
	/** 当前查看类型 */
	type: MESSAGE_TYPE_WITH_ALL;
	/** 当前要post的内容 */
	post: MESSAGE_POST;
}
const initialState: FeedState = {
	messages: [],
	messageLikeCountMap: {},
	loading: false,
	loadAll: false,
	type: '',
	post: {
		open: false,
		editType: EDIT_TYPE.CREATE,
		step: MESSAGE_POST_STEP.NORMAL,
		type: MESSAGE_TYPE.JOB,
		id: '',
		content: '',
		visibility: MESSAGE_VISIBILITY.PUBLIC,
		visibilityTrainingIds: [],
		extendType: ''
	}
};

export const refreshMessages = createAsyncThunk(
	'feed/refreshMessages',
	async (payload: IFetchMessagesPayload) => {
		const messages = await fetchMessages(payload);
		let likedResult: IFetchLikeCountItemsListResponse[] = [];
		try {
			likedResult = await fetchLikeCountByItemIdList({
				tableName: EInformationType.MESSAGE,
				itemId: messages.map(item => item.id)
			});
		} catch (error) {
			likedResult = [];
		}
		return {
			messages,
			likedResult
		};
	}
);

/** 加载更多消息, 带时间戳 */
export const moreMessage = createAsyncThunk(
	'feed/moreMessage',
	async (payload: IFetchMessagesPayload) => {
		if (payload.timestamp) {
			const messages = await fetchMessages(payload);
			let likedResult: IFetchLikeCountItemsListResponse[] = [];
			try {
				likedResult = await fetchLikeCountByItemIdList({
					tableName: EInformationType.MESSAGE,
					itemId: messages.map(item => item.id)
				});
			} catch (error) {
				likedResult = [];
			}
			return {
				messages,
				likedResult
			};
		}
		return {
			messages: [],
			likedResult: []
		};
	}
);
export const updateLikeCountByItemId = createAsyncThunk(
	'feed/updateLikeCountByItemId',
	async (payload: IFetchLikeCountByItemIdListPayload): Promise<ILikeCountItem[]> => {
		const response: IFetchLikeCountItemsListResponse[] = await fetchLikeCountByItemIdList(
			payload
		);
		return response.flatMap(res => res.Items);
	}
);

export const feedSlice = createSlice({
	name: 'feed',
	initialState,
	reducers: {
		updateMessageType: (state: FeedState, action: PayloadAction<string>) => {
			state.type = action.payload as MESSAGE_TYPE_WITH_ALL;
		},
		/** 更新要发布的post的数据 */
		updatePostMessage: (state: FeedState, action: PayloadAction<MESSAGE_POST>) => {
			state.post = action.payload;
		},
		cleanPostMessage: (state: FeedState) => {
			state.post = initialState.post;
		}
	},
	extraReducers: builder => {
		builder.addCase(refreshMessages.pending, (state: FeedState) => {
			state.loading = true;
			state.loadAll = false;
		});
		builder.addCase(
			refreshMessages.fulfilled,
			(
				state,
				action: PayloadAction<{
					messages: IFetchMessage[];
					likedResult: IFetchLikeCountItemsListResponse[];
				}>
			) => {
				state.messages = action.payload.messages;
				if (action.payload.likedResult && action.payload.likedResult[0]) {
					action.payload.likedResult[0].Items.forEach(item => {
						state.messageLikeCountMap[item.itemId] = item.counts;
					});
				}
				state.loading = false;
				if (action.payload.messages.length < MESSAGE_LIST_MAX) {
					state.loadAll = true;
				}
			}
		);
		builder.addCase(
			moreMessage.fulfilled,
			(
				state,
				action: PayloadAction<{
					messages: IFetchMessage[];
					likedResult: IFetchLikeCountItemsListResponse[];
				}>
			) => {
				if (action.payload.messages) {
					state.messages = [...state.messages, ...action.payload.messages];
				}
				if (action.payload.likedResult && action.payload.likedResult[0]) {
					action.payload.likedResult[0].Items.forEach(item => {
						state.messageLikeCountMap[item.itemId] = item.counts;
					});
				}
				if (action.payload.messages.length === 0) {
					state.loadAll = true;
				}
			}
		);
		builder.addCase(updateLikeCountByItemId.fulfilled, (state, action) => {
			action.payload.forEach(countItem => {
				state.messageLikeCountMap[countItem.itemId] = countItem.counts;
			});
		});
	}
});

export const { updateMessageType, updatePostMessage, cleanPostMessage } = feedSlice.actions;
export const selectFeedState = (state: RootState) => state.feed;
export default feedSlice.reducer;
