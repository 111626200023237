'use client';

import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import Button from '@/components/UI/Button';
import { ETrainingType } from '@/constants/training';
import { textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const headerBgColor = {
	[ETrainingType.TRAINING]: color.primaryYellow,
	[ETrainingType.VIDEO]: color.primaryGreen,
	[ETrainingType.INTERNSHIP]: color.primaryBlue
};

const CardContainer = styled.div`
	background-color: #fff;
	border: solid 1px #000;
	border-radius: 2px;
	display: flex;
	flex-direction: column;
	width: 100%;
	@media ${devices.tablet} {
		min-height: 414px;
	}
`;
const Header = styled.div<{ bgColor: string }>`
	background-color: ${({ bgColor }) => bgColor};
	color: #000;
	font-size: 14px;
	padding: 6px 16px;
`;
const Content = styled.div`
	color: #000;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 16px;
`;
const Title = styled.h3`
	font-size: 16px;
	font-weight: 600;
	margin: 0;
	${textEllipsis(1)}
	@media ${devices.tablet} {
		height: 60px;
		${textEllipsis(2)}
		font-size: 22px;
	}
`;
const Description = styled.p`
	font-size: 14px;
	height: 44px;
	${textEllipsis(2)}
	@media ${devices.tablet} {
		margin-top: 16px;
		height: 64px;
		${textEllipsis(3)}
	}
`;
const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	min-height: 158px;
	@media ${devices.tablet} {
		margin-top: 16px;
	}
`;
const InfoItem = styled.div`
	align-items: center;
	border-bottom: dotted 1px #666;
	display: flex;
	font-size: 14px;
	height: 32px;
	margin: 0;
	padding: 0 5px;
	:first-of-type {
		border-top: dotted 1px #666;
	}
	span {
		font-weight: 600;
	}
`;
const InfoItemIcon = styled(Image)`
	margin-right: 6px;
`;
const InfoContent = styled.p`
	line-height: 30px;
	${textEllipsis(1)};
	margin: 0;
`;
const BottomContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	width: 100%;
`;
const PriceBox = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	min-width: 10px;
`;
const Price = styled.p`
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
	margin: 0;
`;
const OriginalPrice = styled.p`
	color: ${color.textSecondaryColor};
	font-size: 12px;
	font-weight: bold;
	line-height: 1;
	margin: 0;
	text-decoration: line-through;
`;

export enum COURSE_CARD_DISPLAY_TYPE {
	TRAINING = 'training',
	PROGRAM = 'program',
	INTERNSHIP = 'internship'
}

interface CourseCardProps {
	cardLabel: string;
	type: ETrainingType;
	title: string;
	description: string;
	startDate?: string;
	duration: string;
	num: number;
	level: string;
	slug: string;
	/** 价格，仅用于展示 */
	price?: number;
	/** 原价 */
	originalPrice?: number;
	displayType?: COURSE_CARD_DISPLAY_TYPE;
}

const CourseCard: React.FC<CourseCardProps> = ({
	cardLabel,
	type,
	title,
	description,
	startDate,
	duration,
	num,
	level,
	slug,
	price,
	originalPrice,
	displayType = COURSE_CARD_DISPLAY_TYPE.TRAINING
}) => {
	const getPrice = () => {
		if (!!price || !!originalPrice) {
			return <Price>${price || originalPrice}</Price>;
		}
		return '';
	};
	const getOriginalPrice = () => {
		if (price && originalPrice && !!originalPrice) {
			return <OriginalPrice>${originalPrice}</OriginalPrice>;
		}
		return '';
	};
	const getPriceBox = () => {
		if (type === ETrainingType.INTERNSHIP || type === ETrainingType.VIDEO) {
			return (
				<PriceBox>
					{getPrice()}
					{getOriginalPrice()}
				</PriceBox>
			);
		}
		return <PriceBox />;
	};

	const renderStartDateIcon = (
		<InfoItemIcon
			src="/image/study/recommend-course-start.svg"
			alt="start-icon"
			width={14}
			height={14}
			loader={imageLoader}
			unoptimized
		/>
	);

	const renderStartDateContent = () => {
		switch (true) {
			case !!startDate: {
				return (
					<InfoItem>
						{renderStartDateIcon}
						<InfoContent>
							{displayType === COURSE_CARD_DISPLAY_TYPE.TRAINING
								? '下一期开课：'
								: '开课日期：'}
							<span>{startDate}</span>
						</InfoContent>
					</InfoItem>
				);
			}
			case type === ETrainingType.INTERNSHIP: {
				return (
					<InfoItem>
						<InfoContent>
							{renderStartDateIcon}
							<span>随时开课</span>
						</InfoContent>
					</InfoItem>
				);
			}
			default: {
				return null;
			}
		}
	};

	return (
		<CardContainer>
			<Header bgColor={headerBgColor[type as ETrainingType] || color.primaryYellow}>
				{cardLabel}
			</Header>
			<Content>
				<div>
					<Link href={`/program-course/${slug}`}>
						<Title>{title}</Title>
					</Link>
					<Description>{description}</Description>
				</div>
				<div>
					<InfoContainer>
						{renderStartDateContent()}

						<InfoItem>
							<InfoItemIcon
								src="/image/study/recommend-course-duration.svg"
								alt="duration-icon"
								width={14}
								height={14}
								loader={imageLoader}
								unoptimized
							/>
							<InfoContent>{duration}</InfoContent>
						</InfoItem>
						<InfoItem>
							<InfoItemIcon
								src="/image/study/recommend-course-num.svg"
								alt="num-icon"
								width={14}
								height={14}
								loader={imageLoader}
								unoptimized
							/>
							<InfoContent>
								累计<span>{num}人</span>报名
							</InfoContent>
						</InfoItem>
						<InfoItem>
							<InfoItemIcon
								src="/image/study/recommend-course-level.svg"
								alt="level-icon"
								width={14}
								height={14}
								loader={imageLoader}
								unoptimized
							/>
							<span>{level}</span>
						</InfoItem>
					</InfoContainer>
					<BottomContainer>
						{getPriceBox()}
						<Button theme="default" color="default" href={`/program-course/${slug}`}>
							查看详情
						</Button>
					</BottomContainer>
				</div>
			</Content>
		</CardContainer>
	);
};

export default CourseCard;
