import {
	MenuItem,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	SelectChangeEvent
} from '@mui/material';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { getMenuItemStyles } from '@/utils/style';

const StyledSelect = styled(MuiSelect<string>)`
	ul {
		display: flex;
		flex-direction: column;
	}
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface SelectProps extends MuiSelectProps<string> {
	value: string;
	options: {
		label: string;
		value: string;
	}[];
	onChange: (e: SelectChangeEvent) => void;
	placeholder?: string;
	style?: CSSProperties;
}

const Select = ({ value, options, onChange, placeholder, ...props }: SelectProps) => {
	return (
		<StyledSelect
			value={value}
			onChange={onChange}
			displayEmpty
			inputProps={{ 'aria-label': 'Without label' }}
			renderValue={v => {
				if (!v) {
					return placeholder;
				}
				return options.find(item => item.value === v)?.label;
			}}
			{...props}
		>
			{options.map(option => {
				return (
					<MenuItem key={option.value} value={option.value} style={getMenuItemStyles()}>
						{option.label}
					</MenuItem>
				);
			})}
		</StyledSelect>
	);
};

export default Select;
