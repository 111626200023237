import request, { HTTP_METHOD } from '@/utils/request';

export const fetchMaterialPresignedUrl = (id: string): Promise<FetchData<FetchPresignedUrl>> => {
	return request<FetchData<FetchPresignedUrl>>(HTTP_METHOD.GET, `/materials/${id}/presigned-url`);
};

export const fetchMaterialsForStudy = async (
	payload: IMaterialsForStudyReq
): Promise<TPagination<IMaterialBase>> => {
	return request<FetchData<TPagination<IMaterialBase>>>(
		HTTP_METHOD.GET,
		'/materials/study-resource',
		{
			query: payload
		}
	);
};

export const fetchMaterialTagsForStudy = async (payload: {
	trainingId?: string;
	fileType?: string;
	keyword?: string;
}): Promise<IMaterialTypes[]> => {
	return request<FetchData<IMaterialTypes>>(HTTP_METHOD.GET, '/materials/study-resource-tags', {
		query: payload
	});
};

export default {};
