import { PARAMS_STUDY_CENTER_TAB_VALUE } from './searchParams';

export enum STUDY_LINK_TYPE {
	/** 课堂顾问 */
	CAREER_ADVISOR = 'career-advisor',
	/** 职业社群 */
	CAREER_COMMUNITY = 'career-community',
	/** 职业规划工具 */
	CAREER_PLANNING = 'career-planning',
	/** 面试辅导 */
	JOB_COUNSELING = 'job-counseling',
	/** 简历辅导 */
	JOB_RESUME = 'job-resume',
	/** 模拟面试 */
	JOB_INTERVIEW = 'job-interview',
	/** 职业规划 */
	JOB_PLANNING = 'job-planning',
	/** 薪资突破 */
	JOB_INCREASE = 'job-increase',
	/** 上岸方案制定 */
	JOB_FORMULATE = 'job-formulate',
	/** AI线上简历 */
	JOB_AI = 'job-ai',
	/** 学习资料 */
	MATERIAL_GREEN = 'material-green',
	/** 求职模板 */
	MATERIAL_YELLOW = 'material-yellow',
	/** 学习笔记 */
	MATERIAL_BLUE = 'material-blue',
	/** 行业资料库 */
	MATERIAL_RED = 'material-red',
	/** 校招汇总 */
	MATERIAL_DARK_BLUE = 'material-dark-blue',
	/** 工作招聘 */
	MATERIAL_PURPLE = 'material-purple'
}
export const ICON_PATHS = {
	study: '/image/study/wiki_yellow.svg',
	student: '/image/study/wiki_purple.svg',
	jobSeeker: '/image/study/wiki_green.svg',
	assessment: '/image/study/wiki_blue.svg',
	interview: '/image/study/wiki_green.svg',
	career: '/image/study/wiki_purple.svg',
	default: '/image/study/wiki_yellow.svg'
};

export const DEFAULT_SUBTITLE = '精选优质内容，助你提升职业技能';

export const careerNavigation: NavLink[] = [
	{
		title: '课堂顾问',
		link: '#',
		iconSrc: '/image/study/career-nav-advisor.svg',
		type: STUDY_LINK_TYPE.CAREER_ADVISOR,
		isShow: true
	},
	{
		title: '职业社群',
		link: '/career-community',
		iconSrc: '/image/study/career-nav-community.svg',
		// type: STUDY_LINK_TYPE.CAREER_COMMUNITY,
		isShow: true,
		isNew: true
	},
	{
		title: '职业规划工具',
		link: '/study/career-planning',
		iconSrc: '/image/study/career-nav-planning.svg',
		// type: STUDY_LINK_TYPE.CAREER_PLANNING,
		isShow: true
	},
	{
		title: 'AI线上简历',
		link: 'https://jobpin.com.au/',
		targetBlank: true,
		iconSrc: '/image/study/job-nav-ai.svg',
		isShow: true
	},
	{
		title: '我的活动',
		link: '/event-orders',
		targetBlank: true,
		iconSrc: '/image/study/my-activities.svg',
		isNew: true,
		isShow: true
	}
];

export const jobNavigation: NavLink[] = [
	{
		title: '面试辅导',
		link: '#',
		iconSrc: '/image/study/job-nav-counseling.svg',
		type: STUDY_LINK_TYPE.JOB_COUNSELING
	},
	{
		title: '简历辅导',
		link: '#',
		iconSrc: '/image/study/job-nav-resume.svg',
		type: STUDY_LINK_TYPE.JOB_RESUME
	},
	{
		title: '模拟面试',
		link: '#',
		iconSrc: '/image/study/job-nav-interview.svg',
		type: STUDY_LINK_TYPE.JOB_INTERVIEW
	},
	{
		title: '职业规划',
		link: '#',
		iconSrc: '/image/study/job-nav-planning.svg',
		type: STUDY_LINK_TYPE.JOB_PLANNING
	},
	{
		title: '薪资突破',
		link: '#',
		iconSrc: '/image/study/job-nav-increase.svg',
		type: STUDY_LINK_TYPE.JOB_INCREASE
	},
	{
		title: '上岸方案制定',
		link: '#',
		iconSrc: '/image/study/job-nav-formulate.svg',
		type: STUDY_LINK_TYPE.JOB_FORMULATE
	}
];

export const defaultProfessionalResourcesNavigation: NavLink[] = [
	{
		title: '学习资料',
		link: '/study/material',
		iconSrc: '/image/study/material-green.svg',
		isShow: true,
		subtitle: '优质资源，提升技能，巩固知识'
	}
];

export const toolsNavigation: NavLink[] = [
	{
		title: '个人税收计算器',
		link: 'https://easy-tax.jiangren.com.au/',
		iconSrc: '/image/study/calculator.svg',
		targetBlank: true,
		isNew: true
	},
	{
		title: 'GPA计算器',
		link: 'https://unimelb-gpa.jiangren.com.au/',
		iconSrc: '/image/study/gpa-calculator.svg',
		targetBlank: true,
		isNew: true,
		suffix: '(UniMelb)'
	},
	{
		title: 'GPA计算器',
		link: 'https://uq-gpa.jiangren.com.au',
		iconSrc: '/image/study/gpa-calculator.svg',
		targetBlank: true,
		isNew: true,
		suffix: '(UQ)'
	}
];

export const navLabel = {
	[PARAMS_STUDY_CENTER_TAB_VALUE.STUDY]: '学习中心',
	[PARAMS_STUDY_CENTER_TAB_VALUE.CLASS]: '我的课程',
	[PARAMS_STUDY_CENTER_TAB_VALUE.MATERIAL]: '我的资料',
	[PARAMS_STUDY_CENTER_TAB_VALUE.P3]: 'P3组队',
	[PARAMS_STUDY_CENTER_TAB_VALUE.JOBPIN_AI]: 'JobPinAI',
	[PARAMS_STUDY_CENTER_TAB_VALUE.REFERRAL]: '工作内推',
	[PARAMS_STUDY_CENTER_TAB_VALUE.INTERVIEW]: '面试管理',
	[PARAMS_STUDY_CENTER_TAB_VALUE.CALENDER]: '学习日历'
};

export enum CALENDAR_DATA_TYPE {
	LESSON = 'lesson',
	EVENT = 'event'
}

export enum MATERIAL_FILTER_TYPE {
	BUY = 'buy',
	PUBLIC = 'public'
}

export default {};
