import { color } from '@/styles/variables';

export enum EMeetupEventType {
	JIANGREN = 'jiangren'
}

export enum EMeetupRecommendPlace {
	RECOMMEND = 'recommend'
}

export enum MEETUP_LIST_TYPE {
	INGOING = 'ingoing',
	COMING = 'coming',
	PAST = 'past'
}

export enum MEETUP_TYPE {
	ONLINE = 'online',
	OFFLINE = 'offline'
}

export enum LIVE_STREAMING_OPTIONS {
	ZOOM = 'zoom',
	GOOGLE_MEET = 'meet',
	MICROSOFT_TEAMS = 'teams',
	OTHER = 'other'
}

export const MEETUP_TYPE_TEXT = {
	[MEETUP_TYPE.ONLINE]: '线上活动',
	[MEETUP_TYPE.OFFLINE]: '线下活动'
};

export enum MEETUP_PRICE_TYPE {
	FREE = 'free',
	PRICED = 'paid'
}

export const MEETUP_TYPE_ICONS = {
	[MEETUP_TYPE.OFFLINE]: '/image/event/offline.svg',
	[MEETUP_TYPE.ONLINE]: '/image/event/online.svg'
};

/**
 * 活动类型,用于查询
 */
export enum EVENT_TYPE {
	/** 匠人活动 */
	JR = 'jr',
	/** hackathon */
	HACKATHON = 'hackathon'
}

/**
 * 匠人活动类型
 */
export enum JR_EVENT_TYPE {
	/** 讲座 */
	LECTURE = 'lecture',
	/** 公开课 */
	PUBLIC_COURSE = 'publicCourse',
	/** 学员采访 */
	STUDENT_INTERVIEW = 'studentInterview',
	/** 大学活动 */
	UNIVERSITY_ACTIVITY = 'universityActivity',
	/** 职场面经 */
	WORK_EXPERIENCE = 'workExperience',
	/** WORKSHOP */
	WORKSHOP = 'workshop',
	/** 招聘会 */
	CAREER_FAIR = 'careerFair',
	/** 编程挑战 */
	HACKATHON = 'hackathon'
}

export const JR_EVENT_TYPE_TEXT: Record<JR_EVENT_TYPE, string> = {
	[JR_EVENT_TYPE.LECTURE]: '讲座',
	[JR_EVENT_TYPE.PUBLIC_COURSE]: '公开课',
	[JR_EVENT_TYPE.STUDENT_INTERVIEW]: '学员采访',
	[JR_EVENT_TYPE.UNIVERSITY_ACTIVITY]: '大学活动',
	[JR_EVENT_TYPE.WORK_EXPERIENCE]: '职场面经',
	[JR_EVENT_TYPE.WORKSHOP]: 'Workshop',
	[JR_EVENT_TYPE.CAREER_FAIR]: '招聘会',
	[JR_EVENT_TYPE.HACKATHON]: '编程挑战'
};

export enum MEETUP_SHARE_IMAGE_TYPE {
	/** 布里斯班专场 */
	BRISBANE = 'brisbane',
	/** 墨尔本专场 */
	MELBOURNE = 'melbourne',
	/** 悉尼专场 */
	SYDNEY = 'sydney',
	/** 阿德莱德专场 */
	ADELAIDE = 'adelaide',
	/** 求职讲座 */
	JOB_LECTURE = 'jobLecture',
	/** 求职故事分享 */
	JOB_STORY = 'jobStory',
	/** 干货讲座 */
	PRACTICAL_LECTURE = 'practicalLecture',
	/** 职场分享会 */
	CAREER_SHARE = 'careerShare',
	/** 公开课 */
	OPEN_CLASS = 'openClass'
}

export enum EEventZoomMeetingRole {
	HOST = 'host',
	SPEAKER = 'speaker',
	GUEST = 'guest',
	ATTENDEE = 'attendee'
}

export const JOIN_EVENT_MEETING_BUTTON_TEXT = {
	[EEventZoomMeetingRole.HOST]: '主持人开启直播',
	[EEventZoomMeetingRole.SPEAKER]: '嘉宾入口',
	[EEventZoomMeetingRole.GUEST]: '嘉宾入口',
	[EEventZoomMeetingRole.ATTENDEE]: '进入直播'
};

export const RECRUITMENT_BENEFITS: IRecruitment = {
	title: '能为您带来什么收益？',
	items: [
		{
			id: 1,
			title: '品牌曝光',
			description:
				'通过多渠道宣传，包括小红书、微信公众号、线上线下活动及求职社群，让您的品牌触达深圳IT行业核心人群，提升品牌知名度和行业影响力。'
		},
		{
			id: 2,
			title: '精准人才获取',
			description:
				'活动吸引大量IT求职者与专业人士，为企业提供优质候选人资源，通过两份筛选与线下面试环节，精准匹配企业需求，提升招聘效率。'
		},
		{
			id: 3,
			title: '潜在合作机会',
			description:
				'与其他企业代表面对面交流，拓展合作渠道，发掘潜在商业伙伴，建立长期合作关系。'
		},
		{
			id: 4,
			title: '成本优化',
			description: '通过集中招聘活动降低单一招聘成本，在有限预算内获得更高效的人才资源。'
		},
		{
			id: 5,
			title: '持续人才对接',
			description:
				'活动结束后，企业将持续享受匹配学院的人才推荐服务，接触新一代IT人才与经验丰富的专业人士。'
		}
	]
};

export const RECRUITMENT_SPONSORSHIP: IRecruitment = {
	title: '如何赞助我们的活动？',
	items: [
		{
			id: 1,
			title: '冠名赞助',
			description:
				'享受活动冠名权，品牌在活动名称、主要宣传内容及活动现场重点露出，最大化曝光量。'
		},
		{
			id: 2,
			title: '定制化合作',
			description: '根据企业需求量身定制宣传与活动方案，确保合作效果与品牌目标精准匹配。'
		}
	]
};

export const BENEFIT_NUMBER_BG_COLORS: Record<number, string> = {
	1: color.primaryBlue,
	2: color.primaryPurple,
	3: color.primaryGreen,
	4: color.primaryYellow,
	5: color.primaryRed
};

export const PARTNER_STEPS: IPartnerSteps = {
	title: '如何成为合作方？',
	description:
		'加入匠人学院活动合作，与我们一同打造澳洲IT行业的顶尖活动平台，推动企业品牌成长，实现合作共赢！',
	steps: [
		{
			id: 1,
			title: '联系我们',
			description:
				'通过邮件business@jiangren.com.au或电话0421-672-555与我们联系，了解详细合作方案和权益。'
		},
		{
			id: 2,
			title: '选择合作方案',
			description: '根据您的业务需求与目标，选择适合的赞助方式。'
		},
		{
			id: 3,
			title: '签订合作协议',
			description: '确认合作权益，确保双方共赢。'
		},
		{
			id: 4,
			title: '活动共推',
			description: '与匠人学院携手，通过社交平台、社群及活动宣传，共同提升活动效果。'
		}
	]
};

export const EVENT_SLIDER_IMAGES: IEventSliderImage[] = [
	{
		id: 1,
		src: '/image/event/event-slider-1.webp',
		alt: 'event slider image',
		bgColor: color.primaryPurple
	},
	{
		id: 2,
		src: '/image/event/event-slider-2.webp',
		alt: 'event slider image',
		bgColor: color.primaryBlue
	},
	{
		id: 3,
		src: '/image/event/event-slider-3.webp',
		alt: 'event slider image',
		bgColor: color.primaryGreen
	},
	{
		id: 4,
		src: '/image/event/event-slider-4.webp',
		alt: 'event slider image',
		bgColor: color.primaryRed
	},
	{
		id: 5,
		src: '/image/event/event-slider-5.png',
		alt: 'event slider image',
		bgColor: color.primaryBlue
	},
	{
		id: 6,
		src: '/image/event/event-slider-6.webp',
		alt: 'event slider image',
		bgColor: color.primaryYellow
	},
	{
		id: 7,
		src: '/image/event/event-slider-7.webp',
		alt: 'event slider image',
		bgColor: color.primaryPurple
	},
	{
		id: 8,
		src: '/image/event/event-slider-8.webp',
		alt: 'event slider image',
		bgColor: color.primaryBlue
	},
	{
		id: 9,
		src: '/image/event/event-slider-9.webp',
		alt: 'event slider image',
		bgColor: color.primaryRed
	}
];

export const EVENT_CITIES: IEventCity[] = [
	{
		id: 1,
		name: '悉尼',
		bgColor: color.primaryYellow
	},
	{
		id: 2,
		name: '墨尔本',
		bgColor: color.primaryBlue
	},
	{
		id: 3,
		name: '布里斯班',
		bgColor: color.primaryGreen
	},
	{
		id: 4,
		name: '阿德莱德',
		bgColor: color.primaryPurple
	},
	{
		id: 5,
		name: '珀斯',
		bgColor: color.primaryRed
	},
	{
		id: 6,
		name: '新加坡',
		bgColor: '#fffbe5'
	}
];

export const PAGE_SIZE: number = 24;

export default {};
