import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '@/components/UI/Button';
import { boxBorder, boxElevation, textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';
import { getFullUrl } from '@/utils/url';

const Container = styled.div`
	background-color: ${color.whiteColor};
	display: flex;
	flex-direction: column;
	${boxElevation(4, color.blackColor)}
	${boxBorder()}
	gap: 16px;
	padding: 16px 10px;
	width: 100%;
	@media ${devices.tablet} {
		padding: 24px 24px 16px;
	}
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
`;
const MainContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 10px;
	@media ${devices.tablet} {
		gap: 16px;
	}
`;
const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const NameContainer = styled.div`
	align-items: center;
	display: flex;
`;
const Avatar = styled(Image)`
	height: 48px;
	${boxBorder('1px', '50%')}
	object-fit: cover;
	width: 48px;
	@media ${devices.tablet} {
		width: 50px;
		height: 50px;
	}
`;
const Name = styled.h3`
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	margin: 0;
`;
const Linkedin = styled(Link)`
	align-items: center;
	display: flex;
	justify-content: center;
	margin-left: 10px;
`;
const Title = styled.div`
	background-color: ${color.labelBgColorYellow};
	border: solid 1px #000;
	border-radius: 1px;
	font-size: 12px;
	line-height: 1.2;
	max-width: 100%;
	padding: 2px 4px;
	width: fit-content;
	${textEllipsis(1)}
	@media ${devices.tablet} {
		${textEllipsis()}
		padding: 1px 5px;
		font-size: 14px;
	}
`;
const Description = styled.p`
	display: none;
	@media ${devices.tablet} {
		min-height: 68px;
		font-size: 14px;
		display: unset;
	}
`;
const DescriptionClick = styled.span`
	border-bottom: solid 1px ${color.primaryColor};
	color: ${color.primaryColor};
	cursor: pointer;
`;
const DescriptionMobile = styled.p`
	display: unset;
	font-size: 14px;
	line-height: 1.43;
	margin: 0;
	${textEllipsis(2)}
	@media ${devices.tablet} {
		display: none;
	}
`;
const CompanyContainerMobile = styled(Image)`
	display: flex;
	height: 48px;
	object-fit: contain;
	width: 100px;
	@media ${devices.tablet} {
		display: none;
	}
`;
const CompanyContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		align-items: center;
		display: flex;
		gap: 16px;
		left: 24px;
		margin-bottom: 16px;
		margin-top: 16px;
		max-height: 33px;
		max-width: calc(100% - 48px);
		overflow: hidden;
	}
`;
const Company = styled(Image)`
	height: 33px;
	object-fit: contain;
	width: auto;
`;

const BottomContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

interface TutorCardProps {
	avatar: string;
	name: string;
	linkedin?: string;
	description: string;
	companies?: string[];
	title: string;
	showDetailsButton?: boolean;
	link: string;
}

const maxLength = 60;

const TutorCard = ({
	avatar,
	name,
	title,
	linkedin,
	description,
	companies = [],
	showDetailsButton,
	link
}: TutorCardProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [showExpanded, setShowExpanded] = useState(false);

	useEffect(() => {
		if (description.length > maxLength) {
			setShowExpanded(true);
		}
	}, []);

	const displayText =
		isExpanded || description.length < maxLength
			? `${description} `
			: `${description?.slice(0, maxLength)} ... `;
	const buttonText = isExpanded ? '收起' : '更多';

	return (
		<Container>
			<Header>
				<MainContainer>
					<Avatar
						src={avatar}
						alt={name}
						unoptimized
						loader={imageLoader}
						width={0}
						height={0}
					/>
					<ContentContainer>
						<NameContainer>
							<Link href={link}>
								<Name>{name}</Name>
							</Link>
							{linkedin && (
								<Linkedin href={getFullUrl(linkedin)} target="_blank">
									<Image
										src="/icon/home/linkedin.svg"
										alt={name}
										unoptimized
										loader={imageLoader}
										width={18}
										height={18}
									/>
								</Linkedin>
							)}
						</NameContainer>
						{title && <Title>{title}</Title>}
					</ContentContainer>
				</MainContainer>
				{companies.length > 0 && (
					<CompanyContainerMobile
						key={companies[0]}
						src={companies[0]}
						alt={name}
						unoptimized
						loader={imageLoader}
						width={0}
						height={0}
					/>
				)}
			</Header>
			<Description>
				{displayText}
				{showExpanded && (
					<DescriptionClick onClick={() => setIsExpanded(!isExpanded)}>
						{buttonText}
					</DescriptionClick>
				)}
			</Description>
			<DescriptionMobile>{description}</DescriptionMobile>
			<CompanyContainer>
				{companies.map(item => (
					<Company
						key={item}
						src={item}
						alt={name}
						unoptimized
						loader={imageLoader}
						width={0}
						height={0}
					/>
				))}
			</CompanyContainer>
			{showDetailsButton && (
				<BottomContainer>
					<Button theme="default" color="white" href={link}>
						查看详情
					</Button>
				</BottomContainer>
			)}
		</Container>
	);
};

export default TutorCard;
