// common
export const PARAMS_PAGE_INDEX_KEY = 'pageIndex';
export const PARAMS_KEYWORD_KEY = 'keyword';
export const PARAMS_SORT_KEY = 'sort';

// study-center
export const PARAMS_STUDY_CENTER_TRAINING_ID_KEY = 'trainingId';
export const PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY = 'resourceType';
export const PARAMS_STUDY_CENTER_FILE_TYPE_KEY = 'fileType';
export const PARAMS_STUDY_CENTER_IS_PUBLIC = 'isPublic';

export const PARAMS_STUDY_CENTER_TAB_KEY = 'tab';
export const PARAMS_STUDY_CENTER_MODE_KEY = 'mode';
export const PARAMS_STUDY_CENTER_EDIT_TEAM_KEY = 'edit-team-type';
export const PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY = 'create-team-step';

export enum PARAMS_STUDY_CENTER_TAB_VALUE {
	STUDY = 'study',
	CLASS = 'class',
	MATERIAL = 'material',
	P3 = 'p3',
	JOBPIN_AI = 'jobpin-ai',
	REFERRAL = 'referral',
	INTERVIEW = 'interview',
	CALENDER = 'calender'
}

export enum PARAMS_STUDY_CENTER_MODE_VALUE {
	CREATE_TEAM = 'create-team',
	EDIT_TEAM = 'edit-team'
}

export enum PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE {
	TEAM_DETAIL = 'team-detail',
	MEMBERS = 'members'
}

export enum PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE {
	TEAM_BASIC_INFO = 'team-basic-info',
	PROJECT_BASIC_INFO = 'project-basic-info',
	PROJECT_DETAIL_INFO = 'project-detail-info',
	TEAM_MEMBER = 'team-member'
}

export enum EVIDEO_FILTER {
	ALL = 'all',
	TEACHER = 'teacher',
	STUDENT = 'student',
	INTERVIEW = 'interview'
}
