'use client';

import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { FOLLOW_US_ICONS } from '@/constants/common';
import { pageContainer } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const SectionContainer = styled.div`
	background-color: ${color.primaryDark};
	color: ${color.whiteColor};
	width: 100%;
`;
const Section = styled.div`
	${pageContainer('1440px')};
	padding: 30px 16px;
	@media ${devices.tablet} {
		padding: 80px 24px;
	}
	@media ${devices.largeLaptop} {
		padding: 80px 0;
	}
`;
const Content = styled.div``;
const Container = styled.div`
	color: ${color.whiteColor};
	display: flex;
	@media ${devices.tablet} {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	flex-direction: column;
`;
const CoverWrapper = styled.div`
	display: none;
	max-width: 510px;
	width: 40vw;
	@media ${devices.tablet} {
		display: inline-block;
	}
`;
const CoverWrapperMobile = styled.div`
	display: inline-block;
	margin-bottom: 16px;
	width: 100%;
	@media ${devices.tablet} {
		margin-bottom: 40px;
		display: none;
	}
`;
const MainContent = styled.div``;
const Title = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	p {
		font-size: 26px;
		font-weight: 600;
		margin: 0 0;
	}
	@media ${devices.tablet} {
		gap: 12px;
		p {
			font-size: 38px;
			font-weight: 600;
			line-height: 1.32;
			margin: 0 0;
		}
	}
`;
const ConnectContainer = styled.div`
	align-items: start;
	display: flex;
	flex-direction: column;
	gap: 32px;
	@media ${devices.tablet} {
		gap: 40px;
		align-items: end;
		flex-direction: row;
	}
`;
const QRCodeContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const QRCodeContainerMobile = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	@media ${devices.tablet} {
		display: none;
	}
`;
const QRCodeLabel = styled.span`
	font-size: 14px;
`;
const FollowContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
`;
const FollowContainerTitle = styled.p`
	font-size: 14px;
	line-height: 1;
	margin: 0 0;
`;
const FollowIconsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
	width: 100%;
	@media ${devices.tablet} {
		gap: 12px;
	}
`;
const ButtonWrapper = styled.div`
	align-items: end;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	width: 100%;
	@media ${devices.tablet} {
		width: fit-content;
	}
`;

const ContentBg = styled.div`
	display: none;
	@media ${devices.tablet} {
		display: block;
		background-image: url('/image/dot.svg');
		height: 62px;
		margin-top: 30px;
		width: 100%;
		height: 62px;
	}
`;

const Subtitle = styled.h2`
	color: ${color.primaryColor};
	font-size: 26px;
	@media ${devices.tablet} {
		font-size: 38px;
	}
	margin: 0;
`;

const GetInTouch = () => {
	return (
		<SectionContainer>
			<Section>
				<Content>
					<Container>
						<CoverWrapperMobile>
							<Image
								src="/image/home/get-in-touch.webp"
								alt="get-in-touch"
								unoptimized
								loader={imageLoader}
								width={0}
								height={0}
								style={{
									width: '100%',
									height: 'auto'
								}}
							/>
						</CoverWrapperMobile>
						<MainContent>
							<Title>
								<p>Get in Touch</p>
								<Subtitle>学IT, 来匠人, 拿offer</Subtitle>
							</Title>
							<ConnectContainer>
								<QRCodeContainer>
									<Image
										src="/image/qrcode/xiaohua.webp"
										alt="xiaohua"
										width={112}
										height={112}
										unoptimized
										loader={imageLoader}
									/>
									<QRCodeLabel>1v1免费职业咨询</QRCodeLabel>
								</QRCodeContainer>
								<FollowContainer>
									<FollowContainerTitle>Follow Us</FollowContainerTitle>
									<FollowIconsWrapper>
										{FOLLOW_US_ICONS.map(item => (
											<Link key={item.link} href={item.link}>
												<Image
													src={item.src}
													alt={item.link}
													unoptimized
													loader={imageLoader}
													width={30}
													height={30}
												/>
											</Link>
										))}
									</FollowIconsWrapper>
									<ButtonWrapper>
										<QRCodeContainerMobile>
											<Image
												src="/image/qrcode/xiaohua.webp"
												alt="xiaohua"
												width={112}
												height={112}
												unoptimized
												loader={imageLoader}
											/>
											<QRCodeLabel>1v1免费职业咨询</QRCodeLabel>
										</QRCodeContainerMobile>
									</ButtonWrapper>
								</FollowContainer>
							</ConnectContainer>
							<ContentBg />
						</MainContent>
						<CoverWrapper>
							<Image
								src="/image/home/get-in-touch.webp"
								alt="get-in-touch"
								unoptimized
								loader={imageLoader}
								width={0}
								height={0}
								style={{
									width: '100%',
									height: 'auto'
								}}
								loading="lazy"
							/>
						</CoverWrapper>
					</Container>
				</Content>
			</Section>
		</SectionContainer>
	);
};

export default GetInTouch;
