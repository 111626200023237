const DEFAULT = 'default';

export const PATH_ADMIN = '/admin';
export const PATH_ONBOARDING = '/onboarding';
export const PATH_STUDY_CENTER = '/study-center';
export const PATH_P3_PROJECT = '/p3-project';

export const REVALIDATE_TIME = 60 * 20; // 20 minutes

export const JR_S3_IMAGE_REG_FIRST = /jr-image\.s3\.ap-southeast-2\.amazonaws\.com/g;
export const JR_S3_IMAGE_REG_SECOND = /jr-image\.s3\.amazonaws\.com/g;
export const JR_IMAGE_BASE_URL = 'image.jracademy.com.au';

export enum MODAL_STATUS {
	WAIT = 'waiting',
	SUCCESS = 'success',
	FAIL = 'fail'
}

/** 头像最大大小 */
export const AVATAR_MAX_LIMIT = 10 * 1024 * 1024; // 10MB
export const S3_PATH_AVATAR = 'user/avatar';
export const S3_PATH_INTERVIEW = 'interview/image';
export const S3_PATH_LAB_IMAGE = 'lesson-lab/image';
export const S3_PATH_LAB_VIDEO = 'lesson-lab/video';
export const S3_PATH_POST_IMAGE = 'post/image';
export const S3_PATH_MESSAGE_IMAGE = 'message/image';
export const S3_PATH_JOB_REFERRAL_IMAGE = 'job-application/job-image';

export const outlineStyleRoutes = [PATH_STUDY_CENTER, '/user', '/course'];

export enum ENoteState {
	PUBLISHED = 'published',
	DRAFT = 'draft'
}

export enum E_ALL {
	ALL = ''
}

export const ALL_LABEL = '全部';

export const ALL_TYPE_OPTION = {
	key: 'all',
	label: '全部类型',
	value: ''
};

export const SEARCH_PARAM_REDIRECT_TO = 'redirectTo';

/** localStorage key */
export const LOCAL_STORAGE_CACHE_KEY = {
	/** 当前支付信息  */
	apply: 'apply',
	/** onboarding */
	onboarding: 'onboarding',
	/** study team */
	studyTeamDraft: 'studyTeamDraft'
};

/** 报名付款步骤 */
export enum APPLY_STEP {
	/** 课程选择 */
	PROGRAM_CHOOSE = 'program_choose',
	/** 信息填写 */
	INFORMATION = 'information',
	/** 同意条款 */
	AGREE = 'agree',
	/** 支付方式选择 */
	PAY_METHOD = 'pay_method'
}

export const LOGO_URL = 'https://jiangren.com.au/image/logo.jpg';

export const FOLLOW_US_ICONS: {
	src: string;
	link: string;
	name: string;
}[] = [
	{
		name: 'linkedin',
		src: '/image/home/get-linkedin.svg',
		link: 'https://linkedin.com/school/jr-academy'
	},
	{
		name: 'facebook',
		src: '/image/home/get-facebook.svg',
		link: 'https://www.facebook.com/jracademyau'
	},
	{
		name: 'twitter',
		src: '/image/home/get-twitter.svg',
		link: 'https://twitter.com/jr_academy_au'
	},
	{
		name: 'instagram',
		src: '/image/home/get-instagram.svg',
		link: 'https://www.instagram.com/jracademyau/'
	},
	{
		name: 'weibo',
		src: '/image/home/get-weibo.svg',
		link: 'https://www.weibo.com/ozitquan'
	},
	{
		name: 'youtube',
		src: '/image/home/get-youtube.svg',
		link: 'https://www.youtube.com/channel/UCanQu2BJniNfGViaF23dJgw'
	},
	{
		name: 'bilibili',
		src: '/image/home/get-bilibili.svg',
		link: 'https://space.bilibili.com/357037728'
	},
	{
		name: 'tiktok',
		src: '/image/home/get-tiktok.svg',
		link: 'https://v.douyin.com/iYw42Y2D/'
	},
	{
		name: 'xigua',
		src: '/image/home/get-xigua.svg',
		link: 'https://www.ixigua.com/home/4498825996140964/?list_entrance=search'
	}
];

export enum EBooleanValue {
	TRUE = 'true',
	FALSE = 'false'
}

export const APPLY_AS_INSTRUCTORS_LINK =
	'https://forms.zohopublic.com/JRAcademy/form/Tutor/formperma/qgqAp726xEgsZG9EFaHaxw3pUXgsH07ETLxuTXc0y4A';

export const SORT_BY_PUBLISHED_DATE = {
	DESC: '-publishedDate',
	ASC: 'publishedDate'
};

export const SORT_BY_CREATED_AT = {
	DESC: '-createdAt',
	ASC: 'createdAt'
};

export enum EButtonStep {
	PREV = 'prev',
	NEXT = 'next'
}
export const ButtonStepText = {
	[EButtonStep.PREV]: '上一步',
	[EButtonStep.NEXT]: '下一步'
};

export enum EResponseStatus {
	SUCCESS = 'success',
	FAIL = 'fail'
}

export enum CITY {
	Melbourne = 'Melbourne',
	Sydney = 'Sydney',
	Brisbane = 'Brisbane',
	/** 其他 */
	Other = 'other'
}

export enum HOW_TO_KNOW {
	FRIEND = '朋友推荐',
	TEACHER = '老师介绍',
	EVENT = '活动',
	ADVERTISEMENT = '广告',
	RED_BOOK = '小红书',
	WECHAT_GROUP = '微信',
	OFFICIAL_ACCOUNT = '公众号'
}

export enum EOptionsType {
	/** 课程类型 */
	LESSON_TYPE = 'lessonType',
	/** lesson 可见类型 */
	LESSON_AVAILABLE = 'lessonAvailable',
	/** 所有的training */
	TRAININGS = 'trainings',
	/** training关联的modules */
	TRAINING_MODULES = 'training_modules',
	/** training所有的program关联的syllabus */
	TRAINING_SYLLABUS = 'training_syllabus',
	/** 所有的program */
	PROGRAMS = 'programs',
	/** program关联的training关联的modules */
	PROGRAM_MODULES = 'program_modules',
	/** program关联的lesson */
	PROGRAM_LESSONS = 'program_lessons',
	/** program关联的teacher */
	PROGRAM_TEACHERS = 'program_teachers',
	/** 资料类型 */
	COURSE_RESOURCE_TYPE = 'course_resource_type',
	/** program关联的zoom账号 */
	PROGRAM_ZOOM_ACCOUNT = 'program_zoom_account',
	/** 活动 */
	EVENT = 'event'
}

export enum EDIT_TYPE {
	CREATE = 'create',
	UPDATE = 'update'
}

export const EDIT_TYPE_TEXT = {
	[EDIT_TYPE.CREATE]: '创建',
	[EDIT_TYPE.UPDATE]: '编辑'
};

export const BUTTON_TEXT = {
	CANCEL: '取消',
	SAVE: '保存'
};

export const TIME_ZONES = ['Asia/Shanghai', 'Australia/Sydney'];

export default DEFAULT;
