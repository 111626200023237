import { CircularProgress } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { color as StyleColor } from '@/styles/variables';

export type Color = 'default' | 'white' | 'beige' | 'lightblue';
export type Theme = 'default' | 'link' | 'text';
interface ButtonProps {
	id?: string;
	className?: string;
	/** 主题 */
	theme?: Theme;
	/** 颜色 */
	color?: Color;
	/** 点击事件，如果是跳转链接 */
	onClick?: React.MouseEventHandler;
	/** 是否禁用 */
	disable?: boolean;
	/** 覆盖样式 */
	style?: CSSProperties; // 如果需要覆盖样式再添加
	loading?: boolean;
	children: ReactNode;
	/** 同a的href */
	href?: string;
	/** 同a的target */
	target?: string;
}

const getColor = (color: string, disable: boolean) => {
	return disable ? `${color}80` : color;
};

const getButtonDefaultCommonStyle = (disable: boolean) => css`
	border: solid 1px ${getColor(StyleColor.blackColor, disable)};
	color: ${getColor(StyleColor.blackColor, disable)};
	padding: 10px 20px;
	:hover {
		box-shadow: 2px 2px 0 ${getColor(StyleColor.blackColor, disable)};
		transform: translate(-1px, -1px);
	}
`;

const getDefaultStyleByColor = (color: Color, disable: boolean) => {
	switch (color) {
		case 'white':
			return css`
				background-color: ${getColor(StyleColor.buttonWhite, disable)};
				${getButtonDefaultCommonStyle(disable)}
			`;
		case 'beige':
			return css`
				background-color: ${getColor(StyleColor.buttonBeige, disable)};
				${getButtonDefaultCommonStyle(disable)}
			`;
		case 'lightblue':
			return css`
				background-color: ${getColor(StyleColor.buttonLightBlue, disable)};
				${getButtonDefaultCommonStyle(disable)}
			`;
		case 'default':
		default:
			return css`
				background-color: ${getColor(StyleColor.buttonDefault, disable)};
				border: solid 1px ${getColor(StyleColor.buttonDefault, disable)};
				color: ${getColor(StyleColor.whiteColor, disable)};
				padding: 10px 20px;
				:hover {
					background-color: ${getColor(StyleColor.buttonDefaultHover, disable)};
					border: solid 1px ${getColor(StyleColor.buttonDefaultHover, disable)};
				}
			`;
	}
};

const getThemeStyle = (theme: Theme, color: Color, disable: boolean) => {
	switch (theme) {
		case 'text':
			return css`
				border: none;
			`;
		case 'link':
			return css`
				background-color: unset;
				border: none;
				text-decoration: underline;
			`;
		case 'default':
		default:
			return css`
				${getDefaultStyleByColor(color, disable)}
			`;
	}
};

const StyledButton = styled.button<ButtonProps>`
	align-items: center;
	border-radius: 2px;
	box-sizing: border-box;
	cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
	${props =>
		getThemeStyle(props.theme || 'default', props.color || 'default', props.disable || false)}
	display: flex;
	font-size: 14px;
	justify-content: center;
	line-height: 1;

	transition: all 200ms ease-in-out;
	white-space: nowrap;
`;

const StyledA = styled.a<ButtonProps>`
	align-items: center;
	border-radius: 2px;
	box-sizing: border-box;
	cursor: ${props => (props.disable ? 'not-allowed' : 'pointer')};
	${props =>
		getThemeStyle(props.theme || 'default', props.color || 'default', props.disable || false)}
	display: flex;
	font-size: 14px;
	justify-content: center;
	line-height: 1;

	transition: all 200ms ease-in-out;
	white-space: nowrap;
`;

/**
 * Button组建
 * 参考 http://localhost:6006/?path=/docs/stories-ui-button--docs
 */
const Button = ({
	id,
	className,
	theme = 'default',
	color = 'default',
	onClick,
	children,
	disable,
	loading = false,
	style,
	href,
	target
}: ButtonProps) => {
	const isLink = !!href;
	return isLink ? (
		<StyledA
			id={id}
			className={className}
			theme={theme}
			color={color}
			onClick={disable ? () => {} : onClick}
			style={style}
			disable={disable || loading}
			href={href}
			target={target}
		>
			{loading && (
				<CircularProgress
					size={14}
					style={{
						marginRight: 6
					}}
				/>
			)}
			{children}
		</StyledA>
	) : (
		<StyledButton
			id={id}
			className={className}
			theme={theme}
			color={color}
			onClick={disable ? () => {} : onClick}
			style={style}
			disable={disable || loading}
		>
			{loading && (
				<CircularProgress
					size={14}
					style={{
						marginRight: 6
					}}
				/>
			)}
			{children}
		</StyledButton>
	);
};

export default Button;
