import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { checkCSR } from './check';
import { FINGERPRINT_ID } from '@/constants/storeLocation';

export const getFingerPrint = async () => {
	if (!checkCSR()) {
		return '';
	}
	let fingerprintId = localStorage.getItem(FINGERPRINT_ID);
	if (!fingerprintId) {
		const fp = await FingerprintJS.load();
		const result = await fp.get();
		fingerprintId = result.visitorId || '';
		localStorage.setItem(FINGERPRINT_ID, fingerprintId);
	}
	return fingerprintId;
};

export default getFingerPrint;
