export enum ETrainingType {
	TRAINING = 'training', // Live Bootcamp
	VIDEO = 'video', // Online Video Training, no deadline and commence date
	INTERNSHIP = 'internship' // Program Internship
}

const TRAINING_TYPE_LABEL: Record<ETrainingType, string> = {
	[ETrainingType.TRAINING]: '训练营',
	[ETrainingType.VIDEO]: '视频课程',
	[ETrainingType.INTERNSHIP]: '项目实训营'
};

export enum EBootcampTab {
	DESCRIPTION = 'description',
	SCHEDULE = 'schedule'
}

export enum EBootcampSlug {
	WEB_FULL_STACK = 'web-code-bootcamp-or-learn-to-code-1',
	DEVOPS = 'devops',
	DATA_ANALYST = 'data-analyst-power-bi-python-excel',
	DATA_ENGINEER = 'data-engineer-data-analyst'
}

export enum EBootcampDetailNav {
	/** 课程介绍 */
	INTRODUCTION = 'introduction',
	/** 课程安排 */
	SCHEDULE = 'class-schedule',
	/** 职业前景 */
	OUTLOOK = 'career-prospect'
}

export const BOOTCAMP_DETAIL_NAV_LABEL: Record<EBootcampDetailNav, string> = {
	[EBootcampDetailNav.INTRODUCTION]: '课程介绍',
	[EBootcampDetailNav.SCHEDULE]: '课程安排',
	[EBootcampDetailNav.OUTLOOK]: '职业前景'
};

export default TRAINING_TYPE_LABEL;

export const TRAINING_INTERNSHIP_PROCESS: IInternshipProcess = {
	title: '澳洲实习猿的流程',
	steps: [
		{
			index: 1,
			title: '阶段一：探索发现 - 建立个人职业档案',
			description:
				'通过电话或邮件沟通，了解学生背景、兴趣和技能，创建职业档案。根据档案筛选第一批合作企业。'
		},
		{
			index: 2,
			title: '阶段二：技能提升 - 简历与面试辅导',
			description: '提供简历诊断、LinkedIn优化及面试技巧培训，为实习机会做好充足准备。'
		},
		{
			index: 3,
			title: '阶段三：企业对接 - 实习匹配与面试安排',
			description: '根据档案匹配企业，安排线上或线下面试，进行双向沟通。'
		},
		{
			index: 4,
			title: '阶段四：实习阶段 - 开始实习并跟踪进度',
			description: '学员开始3个月的实习，期间顾问定期跟踪进展，并提供反馈支持。'
		}
	]
} as const;

export const INTERNSHIP_ARRANGEMENT = {
	title: '实习安排',
	arrangements: [
		{
			id: 'period',
			iconSrc: '/icon/training-calendar.svg',
			title: '周期',
			description:
				'3个月，兼职制，每周3-5天，共252至420小时。（每周 3-5 天，具体时间由学生与实习公司商定）'
		},
		{
			id: 'location',
			iconSrc: '/icon/training-location.svg',
			title: '地点',
			description: '全澳城市支持，包括悉尼、墨尔本、布里斯班、阿德莱德等。'
		},
		{
			id: 'format',
			iconSrc: '/icon/training-chat.svg',
			title: '形式',
			description: 'Onsite与Remote灵活选择。'
		},
		{
			id: 'support',
			iconSrc: '/icon/training-star.svg',
			title: '支持',
			description: '随时加入、定制化进度、定期跟踪实习表现。'
		}
	]
};

export const INTERNSHIP_POSITIONS: IInternshipPositions = {
	title: '实习岗位包括',
	positions: [
		{
			id: 'business',
			stepIndex: 1,
			stepIndexBgColor: '#d3dcff',
			title: 'Business and Management',
			description:
				'Accounting、Administration、Finance、Marketing and Communication、Human Resources、Supply Chain Management、Project Management、Event Management、Legal'
		},
		{
			id: 'it',
			stepIndex: 2,
			stepIndexBgColor: '#d3f2ff',
			title: 'Information Technology',
			description:
				'Web Development and Design、Software Programming、Quality Assurance、Cloud Computing、UX/UI、Salesforce Admin and Development Civil Engineering'
		},
		{
			id: 'engineering',
			stepIndex: 3,
			stepIndexBgColor: '#eafec6',
			title: 'Engineering',
			description:
				'Mechanical Engineering、Mechatronics Engineering、Chemical Engineering、Electrical Engineering、Environmental Engineering'
		},
		{
			id: 'creative',
			stepIndex: 4,
			stepIndexBgColor: '#fdecc6',
			title: 'Creative and Design',
			description: 'Architecture and Design、Digital Marketing'
		},
		{
			id: 'other',
			stepIndex: 5,
			stepIndexBgColor: '#ffd3d3',
			title: 'Other Industries',
			description: 'Hospitality, and more.'
		}
	]
} as const;

export const INTERNSHIP_INTRO: InternshipIntro = {
	title: '什么是澳洲实习猿？',
	headingLevel: 'h2',
	description:
		'澳洲实习猿是匠人学院联合多家澳洲本地企业推出的实习项目，旨在解决留学生和新移民在澳洲求职中的核心难题。通过提供真实的商业项目经验和职业辅导，帮助学员填补学校教育与职场需求之间的鸿沟，提升求职竞争力。',
	iconSrc: '/icon/training-double-circle.svg',
	imagePath: '/image/trainings/training-intro.webp'
};
