import Image from 'next/image';
import styled from 'styled-components';

import { boxElevation } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	background-color: ${color.primaryYellow};
	border: solid 1px #000;
	color: ${color.blackColor};
	max-width: 1440px;
	padding: 24px 12px;
	width: 100%;
	${boxElevation(6, color.whiteColor)};
	@media ${devices.tablet} {
		padding: 40px 40px;
		${boxElevation(10, color.whiteColor)};
	}
	width: 100%;
	p {
		margin: 0 0;
	}
`;
const Header = styled.div`
	align-items: center;
	display: flex;
	/* flex-direction: column; */
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	@media ${devices.laptop} {
	}
`;
const HeaderStars = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
	@media ${devices.tablet} {
		gap: 8px;
	}
`;

const TitleContainer = styled.div``;
const Title = styled.p`
	align-items: center;
	display: flex;
	font-size: 16px;
	font-weight: 600;
	justify-content: center;
	width: 100%;
	@media ${devices.laptop} {
		width: fit-content;
	}
`;
const Icons = styled.div`
	display: none;
	@media ${devices.laptop} {
		display: flex;
		justify-content: space-around;
		justify-content: start;
		flex-wrap: wrap;
		gap: 50px;
		margin: 36px 40px 0 40px;
	}
`;
const IconsMobile = styled.div`
	align-items: center;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(3, 1fr);
	justify-content: center;
	margin-top: 30px;
	@media ${devices.laptop} {
		display: none;
	}
`;
const IconMobileWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 34px;
	min-width: 98px;
	&:nth-child(7) {
		display: none;
	}
`;
const StarImage = styled(Image)`
	height: 12px;
	width: 12px;
	@media ${devices.tablet} {
		width: 20px;
		height: 20px;
	}
`;
const CompanyLogoImage = styled(Image)`
	align-items: center;
	display: flex;
	height: 34px;
	justify-content: center;
	@media ${devices.tablet} {
		height: 50px;
	}
	object-fit: contain;
	width: 100%;
	@media ${devices.laptop} {
		width: auto;
	}
`;

interface WorkedAtBoxProps {
	title: string;
	logos: string[];
}

const WorkedAtBox = ({ title, logos }: WorkedAtBoxProps) => {
	const getStarIcon = () => {
		return (
			<StarImage
				src="/icon/home/star.svg"
				alt="star"
				unoptimized
				loader={imageLoader}
				width={0}
				height={0}
			/>
		);
	};
	const getStars = () => {
		return (
			<HeaderStars>
				{getStarIcon()}
				{getStarIcon()}
			</HeaderStars>
		);
	};
	return (
		<Container>
			<Header>
				{getStars()}
				<TitleContainer>
					<Title>{title}</Title>
				</TitleContainer>
				{getStars()}
			</Header>
			<Icons>
				{logos.map(url => (
					<CompanyLogoImage
						src={url}
						alt={url}
						key={url}
						unoptimized
						loader={imageLoader}
						width={0}
						height={0}
					/>
				))}
			</Icons>
			<IconsMobile>
				{logos.map(url => (
					<IconMobileWrapper key={url}>
						<CompanyLogoImage
							src={url}
							alt={url}
							unoptimized
							loader={imageLoader}
							width={0}
							height={0}
						/>
					</IconMobileWrapper>
				))}
			</IconsMobile>
		</Container>
	);
};

export default WorkedAtBox;
