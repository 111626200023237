import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { sum } from 'lodash';

import { EProgramStatus } from '@/components/Pages/StudyPage/ProgramList/ProgramList';
import { FILE_TYPE, RESOURCE_TYPE } from '@/constants/resource';
import {
	PARAMS_KEYWORD_KEY,
	PARAMS_PAGE_INDEX_KEY,
	PARAMS_SORT_KEY,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE,
	PARAMS_STUDY_CENTER_EDIT_TEAM_KEY,
	PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE,
	PARAMS_STUDY_CENTER_FILE_TYPE_KEY,
	PARAMS_STUDY_CENTER_IS_PUBLIC,
	PARAMS_STUDY_CENTER_MODE_KEY,
	PARAMS_STUDY_CENTER_MODE_VALUE,
	PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY,
	PARAMS_STUDY_CENTER_TAB_KEY,
	PARAMS_STUDY_CENTER_TAB_VALUE,
	PARAMS_STUDY_CENTER_TRAINING_ID_KEY
} from '@/constants/searchParams';
import { MATERIAL_FILTER_TYPE } from '@/constants/study';
import { STUDY_TEAM_STEPS } from '@/constants/studyTeam';
import { fetchMaterialTagsForStudy, fetchMaterialsForStudy } from '@/services/material';
import { fetchResourceTags, fetchResources } from '@/services/resource';
import { fetchCalendarData, fetchProgramsList } from '@/services/study';
import { RootState } from '@/store';
import pushHistoryState from '@/utils/pushHistoryState';

const PAGE_SIZE = 24;

interface StudyParams {
	/** 当前tab 的 key */
	[PARAMS_STUDY_CENTER_TAB_KEY]: PARAMS_STUDY_CENTER_TAB_VALUE;
	/** 如果在p3中,当前mode */
	[PARAMS_STUDY_CENTER_MODE_KEY]?: PARAMS_STUDY_CENTER_MODE_VALUE;
	/** 如果在p3创建中,当前步骤 */
	[PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY]?: PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE;
	/** 如果在p3编辑中, 当前状态 */
	[PARAMS_STUDY_CENTER_EDIT_TEAM_KEY]?: PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE;
	// 我的资料
	[PARAMS_STUDY_CENTER_TRAINING_ID_KEY]?: string;
	[PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY]?: RESOURCE_TYPE;
	[PARAMS_STUDY_CENTER_FILE_TYPE_KEY]?: FILE_TYPE;
	[PARAMS_STUDY_CENTER_IS_PUBLIC]?: string;
	[PARAMS_PAGE_INDEX_KEY]?: number;
	[PARAMS_KEYWORD_KEY]?: string;
	[PARAMS_SORT_KEY]?: string;
}

interface IMaterialStore {
	firstLoadFinish?: boolean;
	trainings?: IFetchTraining[];
	materials?: TPagination<IMaterialBase>;
	resources?: TPagination<IResource>;
	resourceTags?: IResourceTypes[];
	materialTags?: IMaterialTypes[];
	totalNum?: number;
}

export interface IPatchMaterialDataPayload {
	params?: StudyParams;
	data?: IMaterialStore;
}

interface IRefreshCalendarDataPayload {
	year: number;
	month: number;
	programId?: string;
}
interface IRefreshCalendarDataRes {
	year: number;
	month: number;
	data: ICalendarDataItem[];
	programId?: string;
}

export interface StudyState {
	/** params路由相关 */
	params: StudyParams;

	programInit: boolean;
	/** 我的课程，所有的program数据 */
	programs: StudyProgramListItem[];
	/** 日历数据 */
	calendarData: ICalendarDataItem[];
	/** 日历月份 */
	calendarMonth: number;
	/** timeline */
	timelineData: ICalendarDataItem[];

	/** 我的资料 */
	materialData: IMaterialStore;
}

const initialState: StudyState = {
	params: {
		[PARAMS_STUDY_CENTER_TAB_KEY]: PARAMS_STUDY_CENTER_TAB_VALUE.STUDY
	},
	programInit: false,
	programs: [],
	calendarData: [],
	calendarMonth: dayjs().month() + 1,
	timelineData: [],
	materialData: {
		firstLoadFinish: false,
		trainings: [],
		totalNum: 0
	}
};

// 更新日历数据
export const refreshCalendarData = createAsyncThunk(
	'study/refreshCalendarData',
	async (payload: IRefreshCalendarDataPayload): Promise<IRefreshCalendarDataRes> => {
		// month: 1~12
		const start = dayjs()
			.year(payload.year)
			.month(payload.month - 1)
			.startOf('month')
			.toISOString();
		const end = dayjs()
			.year(payload.year)
			.month(payload.month + 1)
			.endOf('month')
			.toISOString();
		if (start && end) {
			const data = await fetchCalendarData({ start, end, programId: payload.programId });
			return {
				year: payload.year,
				month: payload.month,
				data,
				programId: payload.programId
			};
		}
		return {
			year: payload.year,
			month: payload.month,
			data: [],
			programId: payload.programId
		};
	}
);
/** 更新学习中心program列表数据 */
export const refreshStudyPrograms = createAsyncThunk(
	'study/refreshPrograms',
	async (): Promise<IFetchPrograms> => {
		const result = await fetchProgramsList();
		return result;
	}
);
/** 更新资料数据 */
export const refreshMaterialData = createAsyncThunk(
	'study/refreshMaterialData',
	async (payload: IPatchMaterialDataPayload): Promise<IMaterialStore> => {
		if (payload.params![PARAMS_STUDY_CENTER_IS_PUBLIC] === MATERIAL_FILTER_TYPE.PUBLIC) {
			const result = await fetchResources({
				pageIndex: payload.params![PARAMS_PAGE_INDEX_KEY] || 1,
				pageSize: PAGE_SIZE,
				isPublic: true,
				resourceType: payload.params![PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY],
				fileType: payload.params![PARAMS_STUDY_CENTER_FILE_TYPE_KEY],
				keyword: payload.params![PARAMS_KEYWORD_KEY],
				sort: payload.params![PARAMS_SORT_KEY]
			});
			const tagResult = await fetchResourceTags({
				isPublic: true,
				fileType: payload.params![PARAMS_STUDY_CENTER_FILE_TYPE_KEY],
				keyword: payload.params![PARAMS_KEYWORD_KEY]
			});
			return {
				resources: result,
				resourceTags: tagResult,
				materials: undefined,
				materialTags: undefined,
				totalNum: sum(tagResult?.map(tag => tag.count)) || 0
			};
		}
		if (
			payload.params![PARAMS_STUDY_CENTER_IS_PUBLIC] === MATERIAL_FILTER_TYPE.BUY &&
			payload.params![PARAMS_STUDY_CENTER_TRAINING_ID_KEY]
		) {
			const result = await fetchMaterialsForStudy({
				pageIndex: payload.params![PARAMS_PAGE_INDEX_KEY] || 1,
				pageSize: PAGE_SIZE,
				trainingId: payload.params![PARAMS_STUDY_CENTER_TRAINING_ID_KEY],
				resourceType: payload.params![PARAMS_STUDY_CENTER_RESOURCE_TYPE_KEY],
				fileType: payload.params![PARAMS_STUDY_CENTER_FILE_TYPE_KEY],
				keyword: payload.params![PARAMS_KEYWORD_KEY],
				sort: payload.params![PARAMS_SORT_KEY]
			});
			const tagResult = await fetchMaterialTagsForStudy({
				trainingId: payload.params![PARAMS_STUDY_CENTER_TRAINING_ID_KEY],
				fileType: payload.params![PARAMS_STUDY_CENTER_FILE_TYPE_KEY],
				keyword: payload.params![PARAMS_KEYWORD_KEY]
			});
			return {
				resources: undefined,
				resourceTags: undefined,
				materials: result,
				materialTags: tagResult,
				totalNum: sum(tagResult?.map(tag => tag.count)) || 0
			};
		}
		return {
			resources: undefined,
			resourceTags: undefined,
			materials: undefined,
			materialTags: undefined,
			totalNum: 0
		};
	}
);

const updateHistoryState = (state: StudyState) => {
	pushHistoryState({ queryParams: state.params });
};

export const studySlice = createSlice({
	name: 'study',
	initialState,
	reducers: {
		initStudyCenter: (state: StudyState, action: PayloadAction<StudyParams>) => {
			state.params = action.payload;
			updateHistoryState(state);
		},
		updateStudyCenterActiveTabParam: (
			state: StudyState,
			action: PayloadAction<PARAMS_STUDY_CENTER_TAB_VALUE>
		) => {
			state.params[PARAMS_STUDY_CENTER_TAB_KEY] = action.payload;
			updateHistoryState(state);
		},
		updateStudyCenterActiveEditTeamParam: (
			state: StudyState,
			action: PayloadAction<PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE>
		) => {
			state.params[PARAMS_STUDY_CENTER_EDIT_TEAM_KEY] = action.payload;
			updateHistoryState(state);
		},
		nextCreateTeamStep: (state: StudyState) => {
			const stepArray = STUDY_TEAM_STEPS;
			const curStepIndex = stepArray.findIndex(
				step => step === state.params[PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY]
			);
			if (curStepIndex === stepArray.length - 1) return;

			state.params[PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY] = stepArray[curStepIndex + 1];
			updateHistoryState(state);
		},
		prevCreateTeamStep: (state: StudyState) => {
			const stepArray = STUDY_TEAM_STEPS;
			const curStepIndex = stepArray.findIndex(
				step => step === state.params[PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY]
			);
			if (curStepIndex === 0) return;

			state.params[PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY] = stepArray[curStepIndex - 1];
			updateHistoryState(state);
		},
		patchMaterialData: (
			state: StudyState,
			action: PayloadAction<IPatchMaterialDataPayload>
		) => {
			state.materialData = {
				...state.materialData,
				...action.payload.data
			};
			state.params = {
				...state.params,
				...action.payload.params
			};
			updateHistoryState(state);
		}
	},
	extraReducers: builder => {
		builder.addCase(
			refreshCalendarData.fulfilled,
			(state, action: PayloadAction<IRefreshCalendarDataRes>) => {
				state.calendarMonth = action.payload.month;
				state.calendarData = action.payload.data;
				if (
					dayjs().year() === action.payload.year &&
					dayjs().month() === action.payload.month &&
					!action.payload.programId
				) {
					state.timelineData = action.payload.data;
				}
			}
		);
		builder.addCase(
			refreshStudyPrograms.fulfilled,
			(state, action: PayloadAction<IFetchPrograms>) => {
				const list = [
					...action.payload.pending
						.sort((a, b) => (dayjs(a.completeDate).isBefore(b.completeDate) ? 1 : -1))
						.map(item => {
							(item as StudyProgramListItem).orderStatus = EProgramStatus.PENDING;
							return item;
						}),
					...action.payload.programs
						.map(item => ({
							...item,
							orderStatus: dayjs().isBefore(dayjs(item.completeDate))
								? EProgramStatus.IN_PROGRESS
								: EProgramStatus.FINISHED
						}))
						.sort((a, b) => {
							if (
								a.orderStatus === EProgramStatus.IN_PROGRESS &&
								b.orderStatus === EProgramStatus.FINISHED
							) {
								return -1;
							}
							if (
								a.orderStatus === EProgramStatus.FINISHED &&
								b.orderStatus === EProgramStatus.IN_PROGRESS
							) {
								return 1;
							}
							return 0;
						})
						.sort((a, b) => {
							if (
								a.orderStatus === EProgramStatus.IN_PROGRESS &&
								b.orderStatus === EProgramStatus.IN_PROGRESS
							) {
								if (a.latestLesson?.commenceDate && !b.latestLesson?.commenceDate) {
									return -1;
								}
								if (!a.latestLesson?.commenceDate && b.latestLesson?.commenceDate) {
									return 1;
								}
								if (
									!a.latestLesson?.commenceDate &&
									!b.latestLesson?.commenceDate
								) {
									return 0;
								}
								return dayjs(a.latestLesson?.commenceDate).isBefore(
									b.latestLesson?.commenceDate
								)
									? -1
									: 1;
							}
							return 0;
						})
						.sort((a, b) => {
							if (
								a.orderStatus === EProgramStatus.FINISHED &&
								b.orderStatus === EProgramStatus.FINISHED
							) {
								if (!a.latestLesson?.commenceDate && b.latestLesson?.commenceDate) {
									return 1;
								}
								if (a.latestLesson?.commenceDate && !b.latestLesson?.commenceDate) {
									return -1;
								}
								if (a.latestLesson?.commenceDate && b.latestLesson?.commenceDate) {
									return dayjs(a.latestLesson?.commenceDate).isBefore(
										dayjs(b.latestLesson?.commenceDate)
									)
										? 1
										: -1;
								}
							}
							return 0;
						})
				];
				state.programs = list;
				state.programInit = true;
			}
		);
		builder.addCase(
			refreshMaterialData.fulfilled,
			(state, action: PayloadAction<IMaterialStore>) => {
				state.materialData = {
					...state.materialData,
					...action.payload
				};
				updateHistoryState(state);
			}
		);
	}
});

export const {
	initStudyCenter,
	updateStudyCenterActiveTabParam,
	updateStudyCenterActiveEditTeamParam,
	nextCreateTeamStep,
	prevCreateTeamStep,
	patchMaterialData
} = studySlice.actions;
export const selectStudyState = (state: RootState) => state.study;
export default studySlice.reducer;
