import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { isObject } from 'lodash';

import { RootState } from '..';

import { PATH_STUDY_CENTER } from '@/constants/common';
import {
	EVENT_ONBOARDING_STEPS,
	ONBOARDING_TYPE,
	STUDY_ONBOARDING_STEPS
} from '@/constants/onboarding';
import { TOKEN, WECHAT_OPENID, WELCOME_MODAL_VISIBLE } from '@/constants/storeLocation';
import { UserRole } from '@/constants/user';
import { TWechatSignInPayload } from '@/interfaces/fetch/wechat.fetch';
import { IUser } from '@/interfaces/user';
import {
	fetchMe,
	fetchUpdateUser,
	fetchUserPasswordUpdate,
	fetchUserSignIn,
	fetchUserSignUp,
	userSignOutApi
} from '@/services/user';
import { fetchWeChatSignIn } from '@/services/wechat';

export interface UserState {
	user: IUser;
}

const initialState: UserState = {
	user: {
		id: '',
		firstName: '',
		lastName: '',
		role: UserRole.STUDENT,
		avatar: '',
		IDNumber: '',
		studentUid: '',
		studentId: '',
		teacherId: '',
		email: '',
		gatherEmail: '',
		wechat: '',
		weChatOpenId: '',
		prefix: '',
		phone: '',
		birthday: '',
		teamsID: '',
		signature: '',
		gender: '',
		/** 工作年数 */
		workingYear: '',
		/** 职业背景 */
		careerBackground: '',
		degree: '',
		/** 技能与兴趣 */
		skillsAndInterests: '',
		/** 是否同意通过微信联系 */
		agreeContactViaWeChat: false,
		grantedGatherAccessPrograms: [],
		country: '',
		city: undefined,
		linkedinUrl: '',
		githubUrl: '',
		currentStatus: '',
		university: { id: '', name: '' },
		universityName: '',
		major: { id: '', name: '' },
		majorName: '',
		employer: { id: '', name: '' },
		company: '',
		position: '',
		lessonPlan: '',
		trainingPurpose: ''
	}
};

export const parseDataToUser = (userData: FetchMe, currentUserData?: IUser): IUser => {
	if (userData.avatar && userData.avatar.url) {
		userData.avatar.url = `${userData.avatar.url}?time=${dayjs().unix()}`; // 方便替换头像后刷新数据
	}
	return {
		...currentUserData,
		id: userData.id,
		firstName: userData.name?.first || '',
		lastName: userData.name?.last || '',
		role: userData.role as UserRole,
		avatar: userData.avatar?.url || '',
		IDNumber: userData.IDNumber,
		...(isObject(userData.student)
			? {
					studentUid: userData.student?.id || '',
					studentId: userData.student?.studentId || ''
			  }
			: {}),
		teacherId:
			((userData.role === UserRole.TEACHER || userData.role === UserRole.ADMIN) &&
				userData.teacher?.id) ||
			'',
		email: userData.email,
		gatherEmail: userData.gatherEmail || '',
		wechat: userData.wechat,
		weChatOpenId: userData.weChatOpenId,
		prefix: userData.prefix || '',
		phone: userData.phone || '',
		birthday: userData.birthday || '',
		teamsID: userData.teamsID || '',
		signature: userData.signature,
		gender: userData.gender,
		/** 工作年数 */
		workingYear: userData.workingYear,
		/** 职业背景 */
		careerBackground: userData.careerBackground,
		/** 学历 */
		degree: userData.degree,
		/** 技能与兴趣 */
		skillsAndInterests: userData.skillsAndInterests,
		/** 是否同意通过微信联系 */
		agreeContactViaWeChat: userData.agreeContactViaWeChat,
		sales: userData.sales,
		grantedGatherAccessPrograms: userData.student?.grantedGatherAccessPrograms || [],
		teacher: userData.teacher,
		country: userData.country,
		city: userData.city,
		currentStatus: userData.currentStatus,
		university: userData.university,
		universityName: userData.universityName,
		major: userData.major,
		majorName: userData.majorName,
		employer: userData.employer,
		company: userData.company,
		position: userData.position,
		objective: userData.objective,
		lessonPlan: userData.student?.lessonPlan,
		trainingPurpose: userData.student?.trainingPurpose,
		linkedinUrl: userData.linkedinUrl,
		githubUrl: userData.githubUrl
	};
};

export const signIn = createAsyncThunk(
	'user/signIn',
	async (payload: { data: { email: string; password: string }; redirectTo: string }) => {
		// 请求token
		await fetchUserSignIn(payload.data);
		// 请求个人信息
		const meRes = await fetchMe();
		return {
			data: {
				token: localStorage.getItem(TOKEN) || '',
				user: meRes
			},
			redirectTo: payload.redirectTo
		};
	}
);

export const signUp = createAsyncThunk(
	'user/signUp',
	async (payload: { data: FetchSignUpRequest; redirectTo: string }) => {
		// 请求token
		await fetchUserSignUp(payload.data);
		// 请求个人信息
		const meRes = await fetchMe();
		// Remove weChatOpenId from session storage
		sessionStorage.removeItem(WECHAT_OPENID);

		localStorage.setItem(WELCOME_MODAL_VISIBLE, 'true');
		return {
			data: {
				token: localStorage.getItem(TOKEN) || '',
				user: meRes
			},
			redirectTo: payload.redirectTo
		};
	}
);

export const updatePassword = createAsyncThunk(
	'user/updatePassword',
	async (payload: { data: FetchPasswordUpdateRequest }) => {
		await fetchUserPasswordUpdate(payload.data);
	}
);

export const signInByWeChat = createAsyncThunk(
	'user/wechatSignIn',
	async (payload: { data: TWechatSignInPayload; redirectTo: string }) => {
		const res = await fetchWeChatSignIn(payload.data);
		if ('weChatOpenId' in res && 'error' in res && res.error === 'INVALID_WECHAT_USER') {
			sessionStorage.setItem(WECHAT_OPENID, res.weChatOpenId);
			window.location.href = `/user/sign-up?redirectTo=${payload.redirectTo}`;
			return null;
		}
		// 请求个人信息
		const meRes = await fetchMe();
		return {
			data: {
				token: localStorage.getItem(TOKEN) || '',
				user: meRes
			},
			redirectTo: payload.redirectTo
		};
	}
);

export const checkMe = createAsyncThunk('user/checkMe', async () => {
	const res = await fetchMe();
	return {
		user: res
	};
});

export const updateMe = createAsyncThunk(
	'user/updateMe',
	async (payload: FetchUpdateUserRequest) => {
		const user = await fetchUpdateUser(payload);
		return {
			user
		};
	}
);

export const signOut = createAsyncThunk(
	'user/signOut',
	async (payload: { redirectTo?: string }) => {
		await userSignOutApi();
		return {
			redirectTo: payload.redirectTo
		};
	}
);

const jumpToOnboarding = (redirectTo?: string) => {
	const redirectIsEvent = redirectTo?.startsWith('/events');
	window.location.replace(
		`/onboarding?type=${redirectIsEvent ? ONBOARDING_TYPE.EVENT : ONBOARDING_TYPE.STUDY}&step=${
			redirectIsEvent ? EVENT_ONBOARDING_STEPS[0] : STUDY_ONBOARDING_STEPS[0]
		}&${redirectTo ? `redirectTo=${redirectTo}` : ''}`
	);
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUser: (state: UserState, action: PayloadAction<Partial<IUser>>) => {
			state.user = {
				...state.user,
				...action.payload
			};
		},
		deleteMe: state => {
			localStorage.clear();
			state.user = initialState.user;
		}
	},
	extraReducers: builder => {
		builder.addCase(
			signIn.fulfilled,
			(
				state: UserState,
				action: PayloadAction<{
					data: {
						token: string;
						user: FetchMe;
					};
					redirectTo: string;
				}>
			) => {
				if (action.payload.data.user && action.payload.data.user.id) {
					const userData = action.payload.data.user;
					// 登录成功
					state.user = parseDataToUser(userData, state.user);
					if (!userData.name || !userData.name.first) {
						jumpToOnboarding(action.payload.redirectTo);
					} else {
						window.location.href = action.payload.redirectTo || PATH_STUDY_CENTER;
					}
				}
			}
		);
		builder.addCase(
			signUp.fulfilled,
			(
				state: UserState,
				action: PayloadAction<{
					data: {
						token: string;
						user: FetchMe;
					};
					redirectTo: string;
				}>
			) => {
				if (action.payload.data.user && action.payload.data.user.id) {
					const userData = action.payload.data.user;
					// 登录成功
					state.user = parseDataToUser(userData, state.user);
					localStorage.setItem(WELCOME_MODAL_VISIBLE, 'true');
					jumpToOnboarding(action.payload.redirectTo);
				}
			}
		);
		builder.addCase(
			signInByWeChat.fulfilled,
			(
				state: UserState,
				action: PayloadAction<{
					data: {
						token: string;
						user: FetchMe;
					};
					redirectTo: string;
				} | null>
			) => {
				if (!action.payload) {
					return;
				}
				if (action.payload.data.user && action.payload.data.user.id) {
					const userData = action.payload.data.user;
					// 登录成功
					state.user = parseDataToUser(userData, state.user);
					if (!userData.name || !userData.name.first) {
						jumpToOnboarding(action.payload.redirectTo);
					} else {
						window.location.replace(action.payload.redirectTo || PATH_STUDY_CENTER);
					}
				}
			}
		);
		builder.addCase(
			checkMe.fulfilled,
			(state: UserState, action: PayloadAction<{ user: FetchMe }>) => {
				if (action.payload.user && action.payload.user.id) {
					const userData = action.payload.user;
					// 登录成功
					state.user = parseDataToUser(userData, state.user);
				}
			}
		);
		builder.addCase(
			updateMe.fulfilled,
			(state: UserState, action: PayloadAction<{ user: FetchMe }>) => {
				if (action.payload.user && action.payload.user.id) {
					const userData = action.payload.user;
					state.user = parseDataToUser(userData, state.user);
				}
			}
		);
		builder.addCase(
			signOut.fulfilled,
			(state: UserState, action: PayloadAction<{ redirectTo?: string }>) => {
				localStorage.removeItem(TOKEN);
				document.cookie = `${TOKEN}=; path=/;`;
				state.user = initialState.user;
				window.location.replace(action.payload.redirectTo || '/');
			}
		);
	}
});

export const { deleteMe, updateUser } = userSlice.actions;

export const selectUserState = (state: RootState) => state.user.user;
export default userSlice.reducer;
