'use client';

import { useState } from 'react';

import NavigationSite from '../../Shares/NavSite/MenuSlide';

import HomeActivities from './HomeActivities';
import HomeCounter from './HomeCounter';
import HomeFeedback from './HomeFeedback/HomeFeedback';
import HomeHero from './HomeHero';
import HomeLectures from './HomeLectures';
import HomeMeta from './HomeMeta';
import HomeOffer from './HomeOffer';
import HomeStudentInterview from './HomeStudentInterview';
import HomeTrainings from './HomeTrainings';
import HomeTutor from './HomeTutor';
import HomeUpComingPrograms from './HomeUpComingPrograms';
import HomeVideos from './HomeVideos';
import PromotionModal from './PromotionModal';
import CooperativeCompany from '@/components/Shares/CooperativeCompany';
import GetInTouch from '@/components/Shares/GetInTouch';
import VideoPlayerModal from '@/components/Shares/Modal/VideoPlayerModal';
import useSocketMeetupStatus from '@/hooks/zoom/useSocketMeetupStatus';

interface HomeProps {
	data: IFetchPageDataHome;
}

const Home = ({ data }: HomeProps) => {
	// Socket for meetup status
	useSocketMeetupStatus();
	// Video for video player modal, also as a control for modal open/close
	const [video, setVideo] = useState<TVideoCard | HomeVideo>();

	const HomePageSections = [
		{ id: 'homeMeetupsRef', text: '匠人学院活动' },
		{ id: 'homeTrainingsRef', text: '训练营' },
		{ id: 'homeUpComingProgramsRef', text: '近期开课' },
		{ id: 'homeLectureRef', text: '匠人TV' },
		{ id: 'homeTutorRef', text: '匠人导师团队' },
		{ id: 'homeStudentInterviewRef', text: '学员故事' },
		{ id: 'homeOfferRef', text: '学员Offer榜' },
		{ id: 'homeFeedbackRef', text: '学员真实评价' }
	];

	return (
		<div className="bg">
			<HomeHero homeHeroVideoUrl={data.heroVideoUrl} />
			<HomeCounter employedAt={data.employeeAt} />
			<div id="homeMeetupsRef">
				<HomeActivities meetups={data.meetups} />
			</div>
			<HomeMeta />
			<div id="homeTrainingsRef">
				<HomeTrainings trainings={data.trainings} />
			</div>
			<div id="homeUpComingProgramsRef">
				<HomeUpComingPrograms programs={data.programs} />
			</div>
			<CooperativeCompany />
			<HomeVideos videos={data.videos} setVideo={setVideo} />

			<div id="homeLectureRef">
				<HomeLectures videos={data.skillVideos} setVideo={setVideo} />
			</div>
			<div id="homeTutorRef">
				<HomeTutor teachers={data.teachers} />
			</div>
			<div id="homeStudentInterviewRef">
				<HomeStudentInterview videos={data.interviewVideos} setVideo={setVideo} />
			</div>
			<div id="homeOfferRef">
				<HomeOffer offers={data.offers} />
			</div>
			<div id="homeFeedbackRef">
				<HomeFeedback feedbacks={data.feedbacks} />
			</div>
			<GetInTouch />
			<VideoPlayerModal video={video} onClose={() => setVideo(undefined)} />
			<NavigationSite sections={HomePageSections} />
			{data.promotionImageUrl && (
				<PromotionModal
					promotionImage={data.promotionImageUrl}
					promotionImageMobile={data.promotionImageMobileUrl}
					promotionUrl={data.promotionLink}
				/>
			)}
		</div>
	);
};

export default Home;
