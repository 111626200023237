import { PATH_STUDY_CENTER } from './common';
import {
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE,
	PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE,
	PARAMS_STUDY_CENTER_MODE_KEY,
	PARAMS_STUDY_CENTER_MODE_VALUE,
	PARAMS_STUDY_CENTER_TAB_KEY,
	PARAMS_STUDY_CENTER_TAB_VALUE
} from './searchParams';

export enum EStudyTeamProjectLevel {
	LEVEL1 = 'level1',
	LEVEL2 = 'level2',
	LEVEL3 = 'level3',
	LEVEL4 = 'level4'
}
export const StudyTeamProjectLevelText = {
	[EStudyTeamProjectLevel.LEVEL1]: 'Level One',
	[EStudyTeamProjectLevel.LEVEL2]: 'Level Two',
	[EStudyTeamProjectLevel.LEVEL3]: 'Level Three',
	[EStudyTeamProjectLevel.LEVEL4]: 'Level Four'
};

export const STUDY_TEAM_STEPS = [
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.TEAM_BASIC_INFO,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.PROJECT_BASIC_INFO,
	PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.PROJECT_DETAIL_INFO
	// PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_VALUE.TEAM_MEMBER
] as const;

export const CREATE_STUDY_TEAM_ROUTE = `${PATH_STUDY_CENTER}?${PARAMS_STUDY_CENTER_TAB_KEY}=${PARAMS_STUDY_CENTER_TAB_VALUE.P3}&${PARAMS_STUDY_CENTER_MODE_KEY}=${PARAMS_STUDY_CENTER_MODE_VALUE.CREATE_TEAM}&${PARAMS_STUDY_CENTER_CREATE_TEAM_STEP_KEY}=${STUDY_TEAM_STEPS[0]}`;

export const TEAM_NAME_MAX = 16;
export const TEAM_SIZE_MIN = 3;
export const TEAM_SIZE_MAX = 30;
export const TEAM_PROJECT_NAME_MAX = 16;
export const TEAM_PROJECT_DESCRIPTION_MAX = 50;

export const PROJECT_NAME_MAX = 16;
export const PROJECT_DESCRIPTION_MAX = 50;

export const EDIT_STUDY_TEAM_NAV_TEXT = {
	[PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE.TEAM_DETAIL]: '组队信息',
	[PARAMS_STUDY_CENTER_EDIT_TEAM_VALUE.MEMBERS]: '成员管理'
};

export enum EStudyTeamMemberRole {
	MANAGER = 'manager',
	LEADER = 'leader',
	MEMBER = 'member'
}

export default {};
