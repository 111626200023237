'use client';

import Image from 'next/image';
import { usePathname } from 'next/navigation';
import styled from 'styled-components';

import Button from '@/components/UI/Button';
import { EGa4Btn } from '@/constants/ga4';
import { pageContainer } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import { sentGA4BtnEvent } from '@/utils/ga4';
import imageLoader from '@/utils/loader';

const Container = styled.section`
	background-color: ${color.primaryLightYellow};
`;

const SectionContainer = styled.div`
	${pageContainer('1440px')};
	display: flex;
	flex-direction: column-reverse;
	padding: 24px 16px;
	@media ${devices.tablet} {
		padding: 24px;
		flex-direction: row;
		justify-content: space-between;
	}

	@media ${devices.largeLaptop} {
		padding: 40px 0;
	}
`;

const InfoSection = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 584px;
	position: relative;
	@media ${devices.tablet} {
		margin-right: 40px;
		max-width: 384px;
	}
	@media ${devices.laptop} {
		margin-right: 60px;
		max-width: 598px;
		&::after {
			background-image: url('/image/home/homeMeta.webp');
			content: '';
			height: 64px;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 80px;
		}
	}
`;

const HomeMetaImage = styled.div`
	margin-bottom: 24px;
	@media ${devices.tablet} {
		margin-bottom: 32px;
		margin-bottom: 0;
		width: 420px;
	}
`;

const TitleContainer = styled.div`
	align-items: center;
	background: linear-gradient(
		${color.primaryLightYellow} 33%,
		${color.whiteColor} 66%,
		${color.labelBgColorYellow}
	);
	display: flex;
	height: 26px;
	margin-bottom: 24px;
	@media ${devices.tablet} {
		margin-bottom: 32px;
	}
	width: fit-content;
`;

const Title = styled.h2`
	font-size: 20px;
	font-weight: 600;
	margin: 0;
	@media ${devices.tablet} {
		font-size: 26px;
	}
	padding: 0;
`;

const DescriptionContainer = styled.ul`
	display: flex;
	flex-direction: column;
	font-size: 14px;
	gap: 16px;
	@media ${devices.tablet} {
		gap: 20px;
	}
	line-height: 22px;
	margin: 0;
	padding-left: 20px;
`;

const DescriptionItemTitle = styled.p`
	display: inline;
	font-size: 14px;
	font-weight: 600;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 24px;
	@media ${devices.tablet} {
		margin-top: 40px;
		justify-content: flex-start;
	}
`;

const StyledImage = styled(Image)`
	height: 100%;
	object-fit: contain;
	width: 100%;
`;

const HomeMeta = () => {
	const pathname = usePathname();

	const handleClick = () => {
		// GA4 Event
		sentGA4BtnEvent(EGa4Btn.HOME_META, pathname);
	};
	return (
		<Container>
			<SectionContainer>
				<InfoSection>
					<TitleContainer>
						<Title>澳洲独创线上互动学习平台</Title>
					</TitleContainer>
					<DescriptionContainer>
						<li>
							<DescriptionItemTitle>学习元宇宙场景融入：</DescriptionItemTitle>
							提升团队协助与沟通效率，增强Networking，与老师与同学互动增强
						</li>
						<li>
							<DescriptionItemTitle>打破远程社交壁垒：</DescriptionItemTitle>
							跨越地理和时间限制，实时互动
						</li>
						<li>
							<DescriptionItemTitle>线上自习室:</DescriptionItemTitle>{' '}
							减少学习孤单感，增强学习动力，找到学习搭子
						</li>
						<li>
							<DescriptionItemTitle>即时反馈和支持：</DescriptionItemTitle>
							教师和助教实时观察学生的学习情况，提供即时的反馈和支持。
						</li>
						<li>
							<DescriptionItemTitle>回归线下上课场景：</DescriptionItemTitle>
							虚拟白板、屏幕共享和多媒体工具的使用使得课堂教学更加生动有趣，有助于提升学生的学习积极性和参与度。
						</li>
					</DescriptionContainer>
					<ButtonContainer>
						<Button href="/metabox" onClick={handleClick}>
							了解匠人元宇宙课堂
						</Button>
					</ButtonContainer>
				</InfoSection>
				<HomeMetaImage>
					<StyledImage
						src="https://image.jracademy.com.au/gather/homeMeta.gif"
						alt="homeMeta Image"
						loader={imageLoader}
						width={0}
						height={0}
						unoptimized
						loading="lazy"
					/>
				</HomeMetaImage>
			</SectionContainer>
		</Container>
	);
};

export default HomeMeta;
