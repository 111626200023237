import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

import { RootState } from '..';

import { LOCAL_STORAGE_CACHE_KEY } from '@/constants/common';
import { ONBOARDING_STEP, ONBOARDING_STEPS, ONBOARDING_TYPE } from '@/constants/onboarding';
import pushHistoryState from '@/utils/pushHistoryState';

interface OnboardingStateSate {
	avatar?: UploadFile;
	country?: string;
	city?: string;
	linkedinUrl?: string;
	githubUrl?: string;
	webSiteUrl?: string;
	currentStatus?: string;
	howToKnow?: string[];
	/** 学习目标，求职意向 */
	objective?: string;
	/** 自评技能 */
	skills?: string[];
}

export interface OnboardingState {
	type: ONBOARDING_TYPE;
	step: ONBOARDING_STEP;
	redirectTo?: string;
	/** 步骤数据 */
	data: OnboardingStateSate;
}
const initialState: OnboardingState = {
	type: ONBOARDING_TYPE.STUDY,
	step: ONBOARDING_STEP.AVATAR,
	redirectTo: '',
	data: {
		avatar: undefined,
		country: '',
		city: undefined,
		linkedinUrl: '',
		githubUrl: '',
		webSiteUrl: '',
		currentStatus: '',
		howToKnow: [],
		objective: '',
		skills: []
	}
};

const updateHistoryState = (state: OnboardingState) => {
	pushHistoryState({
		queryParams: { type: state.type, step: state.step, redirect: state.redirectTo }
	});
};

const saveToLocalStorage = (state: OnboardingState) => {
	localStorage.setItem(LOCAL_STORAGE_CACHE_KEY.onboarding, JSON.stringify(state.data));
};
const loadFromLocalStorage = (state: OnboardingState) => {
	const localStorageData = localStorage.getItem(LOCAL_STORAGE_CACHE_KEY.onboarding);
	if (localStorageData) {
		const data = JSON.parse(localStorageData);
		state.data = data;
	}
};

export const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		/** 更新部分数据 */
		patchOnboardingData: (
			state: OnboardingState,
			action: PayloadAction<OnboardingStateSate>
		) => {
			const patch = pickBy(action.payload, value => value !== undefined);
			state.data = { ...state.data, ...patch };
			saveToLocalStorage(state);
		},
		initOnboardingStep: (
			state: OnboardingState,
			action: PayloadAction<{
				type: ONBOARDING_TYPE;
				step: ONBOARDING_STEP;
				redirectTo?: string;
			}>
		) => {
			state.type = action.payload.type;
			state.step = action.payload.step;
			state.redirectTo = action.payload.redirectTo;
			loadFromLocalStorage(state);
		},
		nextOnboardingStep: (state: OnboardingState) => {
			const stepArray = ONBOARDING_STEPS[state.type];
			const curStepIndex = stepArray.findIndex(step => step === state.step);
			if (curStepIndex === stepArray.length - 1) return;
			state.step = stepArray[curStepIndex + 1];
			updateHistoryState(state);
		},
		prevOnboardingStep: (state: OnboardingState) => {
			const stepArray = ONBOARDING_STEPS[state.type];
			const curStepIndex = stepArray.findIndex(step => step === state.step);
			if (curStepIndex === 0) return;
			state.step = stepArray[curStepIndex - 1];
			updateHistoryState(state);
		}
	}
});

export const { initOnboardingStep, prevOnboardingStep, nextOnboardingStep, patchOnboardingData } =
	onboardingSlice.actions;

export const selectOnboardingState = (state: RootState) => state.onboarding;
export default onboardingSlice.reducer;
