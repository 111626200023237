import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import SecondaryVideo from './SecondaryVideo';
import SecondaryVideoMobile from './SecondaryVideoMobile';
import Button from '@/components/UI/Button';
import { boxBorder, boxElevation, dotBox, pageContainer } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Section = styled.div`
	margin: 32px 0;
	position: relative;
	&:before {
		${dotBox};
		height: 230px;
		left: 0;
		position: absolute;
		top: 76px;
		width: 100%;
	}
	@media ${devices.tablet} {
		margin: 80px 0;
	}
`;

const Container = styled.div`
	${pageContainer('1440px')};
	padding: 0 16px;
	position: relative;
	z-index: 1;
	@media ${devices.tablet} {
		padding: 0 24px;
	}
	@media ${devices.largeLaptop} {
		padding: 0;
	}
`;

const PrimaryVideoContainer = styled.div`
	${boxBorder()}
	${boxElevation(8, '#fff')}
	background-color: ${color.bgColor};
	padding: 16px;
	@media ${devices.mobile} {
		${boxElevation(10, '#fff')}
		padding: 40px 40px 24px;
	}
	@media ${devices.largeLaptop} {
		min-height: 360px;
	}
`;

const PrimaryVideoInfo = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	@media ${devices.laptop} {
		padding-bottom: 24px;
		margin-right: 24px;
		padding-bottom: 0;
	}
	@media ${devices.largeLaptop} {
		margin-right: 130px;
	}
`;

const PrimaryVideoTitle = styled.h3`
	font-size: 20px;
	font-weight: 600;
	line-height: 1.2;
	margin: 0;
	@media ${devices.tablet} {
		margin: 0 0 24px 0;
		font-size: 22px;
	}
	@media ${devices.laptop} {
		font-size: 26px;
		line-height: 28px;
	}
`;

const PrimaryDescription = styled.p`
	font-size: 14px;
	line-height: 22px;
`;

const VideoCover = styled.div`
	cursor: pointer;
	${boxBorder('1px', '1px')}
	margin-bottom: 12px;
	position: relative;
	width: 100%;
	&:before {
		background-image: url('/icon/video-player-icon.svg');
		background-position: center;
		background-repeat: no-repeat;
		content: '';
		height: 44px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 44px;
		z-index: 1;
	}
`;

const PrimaryVideoCoverContainer = styled(VideoCover)`
	display: none;
	height: 40vw;
	@media ${devices.tablet} {
		display: block;
		height: 20vw;
	}
	@media ${devices.largeLaptop} {
		height: calc(500px * 9 / 16);
		width: 500px;
	}
`;

const PrimaryVideoCoverContainerMobile = styled(VideoCover)`
	display: block;
	@media ${devices.tablet} {
		display: none;
	}
`;

const ButtonContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	@media ${devices.tablet} {
		margin-top: 24px;
	}
	@media ${devices.laptop} {
		margin-top: 0;
	}
`;

const MoreButton = styled(Button)`
	align-items: center;
	display: flex;
`;
const MoreButtonText = styled.span`
	margin-right: 6px;
`;

const SecondaryVideoContainer = styled.div`
	display: none;
	margin-top: 36px;
	@media ${devices.tablet} {
		display: flex;
	}
`;
const SecondaryVideoContainerMobile = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 36px;
	overflow-x: scroll;
	@media ${devices.tablet} {
		display: none;
	}
`;
const VideoCoverImage = styled(Image)`
	height: 100%;
	object-fit: cover;
	width: 100%;
`;
const BottomImage = styled(Image)`
	display: none;
	height: 66px;
	width: 84px;
	@media ${devices.tablet} {
		display: block;
	}
`;
const BottomImageMobile = styled(Image)`
	display: block;
	height: 46px;
	width: 60px;
	@media ${devices.tablet} {
		display: none;
	}
`;

const HomeVideos = ({
	videos = [],
	setVideo
}: {
	videos: HomeVideo[];
	setVideo: (video: HomeVideo) => void;
}) => {
	if (isEmpty(videos)) return null;
	return (
		<Section>
			<Container>
				<PrimaryVideoContainer>
					<Grid container>
						<Grid item mobile={12} largeLaptop={6.6}>
							<PrimaryVideoInfo>
								<PrimaryVideoCoverContainerMobile
									onClick={() => setVideo(videos[0])}
								>
									<VideoCoverImage
										src={videos[0].thumbnail}
										alt={videos[0].title}
										loader={imageLoader}
										unoptimized
										width={0}
										height={0}
									/>
								</PrimaryVideoCoverContainerMobile>
								<div>
									<Link href={`/video/${videos[0].slug}`}>
										<PrimaryVideoTitle>{videos[0].title}</PrimaryVideoTitle>
									</Link>
									<PrimaryDescription>{videos[0].description}</PrimaryDescription>
								</div>
								<ButtonContainer>
									<BottomImageMobile
										src="/image/home/video-deco.svg"
										alt="deco"
										width={0}
										height={0}
										loader={imageLoader}
										unoptimized
									/>
									<MoreButton color="beige" href="/videos">
										<MoreButtonText>更多专题</MoreButtonText>
										<Image
											src="/icon/more.svg"
											alt="more"
											width={12}
											height={8}
											loader={imageLoader}
											unoptimized
										/>
									</MoreButton>
									<BottomImage
										src="/image/home/video-deco.svg"
										alt="deco"
										width={0}
										height={0}
										loader={imageLoader}
										unoptimized
									/>
								</ButtonContainer>
							</PrimaryVideoInfo>
						</Grid>
						<Grid item mobile={12} largeLaptop={5.4}>
							<PrimaryVideoCoverContainer onClick={() => setVideo(videos[0])}>
								<VideoCoverImage
									src={videos[0].thumbnail}
									alt={videos[0].title}
									loader={imageLoader}
									unoptimized
									width={0}
									height={0}
								/>
							</PrimaryVideoCoverContainer>
						</Grid>
					</Grid>
				</PrimaryVideoContainer>
				{videos.length > 1 && (
					<>
						<SecondaryVideoContainer>
							<SecondaryVideo videos={videos} setVideo={setVideo} />
						</SecondaryVideoContainer>
						<SecondaryVideoContainerMobile>
							<SecondaryVideoMobile videos={videos} setVideo={setVideo} />
						</SecondaryVideoContainerMobile>
					</>
				)}
			</Container>
		</Section>
	);
};

export default HomeVideos;
