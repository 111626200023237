import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import { FOLLOW_US_ICONS } from '@/constants/common';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	align-items: start;
	border-bottom: solid 1px #000;
	display: flex;
	flex-direction: column;
	gap: 24px;
	justify-content: space-between;
	padding: 32px 0;
	width: 100%;
	@media ${devices.laptop} {
		align-items: center;
		flex-direction: row;
	}
`;
const DisplayContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	@media ${devices.laptop} {
		flex-direction: row;
		gap: 32px;
		justify-content: end;
	}
`;
const IconsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const IconsTitle = styled.p`
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	margin: 0 0;
`;
const Icons = styled.div`
	display: flex;
	gap: 8px;
`;
const FollowUs = styled(Link)`
	height: 24px;
	width: 24px;
`;
const AcceptIcon = styled(Image)`
	background-color: ${color.whiteColor};
	border-radius: 1px;
`;

const PayIcons = [
	'/image/layout/pay-paypal.png',
	'/image/layout/pay-visa.png',
	'/image/layout/pay-master-card.png',
	'/image/layout/pay-airwallex.png',
	'/image/layout/pay-alipay.png'
];

const FooterHeader = () => {
	return (
		<Container>
			<Image
				src="/icon/logo-zh.svg"
				alt="logo"
				unoptimized
				loader={imageLoader}
				width={144}
				height={38}
			/>
			<DisplayContainer>
				<IconsContainer>
					<IconsTitle>Follow Us</IconsTitle>
					<Icons>
						{FOLLOW_US_ICONS.map(item => (
							<FollowUs key={item.link} href={item.link}>
								<Image
									key={item.name}
									src={item.src}
									alt={item.name}
									unoptimized
									loader={imageLoader}
									width={24}
									height={24}
								/>
							</FollowUs>
						))}
					</Icons>
				</IconsContainer>
				<IconsContainer>
					<IconsTitle>We Accept</IconsTitle>
					<Icons>
						{PayIcons.map(item => (
							<AcceptIcon
								key={item}
								src={item}
								alt={item}
								unoptimized
								loader={imageLoader}
								width={56}
								height={24}
							/>
						))}
					</Icons>
				</IconsContainer>
			</DisplayContainer>
		</Container>
	);
};

export default FooterHeader;
