import { Modal as MuiModal } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { EBooleanValue } from '@/constants/common';
import { PROMOTION_MODAL_VISIBLE } from '@/constants/storeLocation';
import useCountdown from '@/hooks/useCountdown';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const ModalContainer = styled(MuiModal)`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: fixed;
	width: 100vw;
`;

const Container = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	&:focus {
		outline: none;
	}
`;

const PromotionTagImg = styled.div`
	left: -10vw;
	position: absolute;
	top: -2.5vw;
	transform: rotate(-10deg);
	width: 30vw;
	img {
		height: auto;
		object-fit: contain;
		width: 100%;
	}
	@media ${devices.tablet} {
		left: -90px;
		top: -26px;
		width: 260px;
	}
	@media ${devices.laptop} {
		left: -114px;
		top: -30px;
		width: 339px;
	}
`;
const TagImage = styled(Image)`
	display: none;
	@media ${devices.tablet} {
		display: block;
	}
`;

const ModalClose = styled.div`
	align-items: center;
	background-color: ${color.whiteColor};
	border: solid 1px ${color.blackColor};
	border-radius: 1px;
	bottom: -56px;
	cursor: pointer;
	display: flex;
	height: 42px;
	justify-content: center;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
	width: 42px;
	@media ${devices.tablet} {
		bottom: -40px;
		left: unset;
		right: -34px;
		top: -40px;
		transform: unset;
	}
`;

const Content = styled.div`
	cursor: pointer;
	max-width: 750px;
	width: 90vw;
	img {
		height: auto;
		object-fit: contain;
		width: 100%;
	}
	@media ${devices.tablet} {
		width: 75vw;
		width: 560px;
	}
	@media ${devices.laptop} {
		width: 750px;
	}
`;

const PromotionModal = ({
	promotionImage,
	promotionImageMobile,
	promotionUrl
}: {
	promotionImage: string;
	promotionImageMobile: string;
	promotionUrl: string;
}) => {
	const router = useRouter();
	const [open, setOpen] = useState<boolean>(false);
	const { countdown, startCountdown } = useCountdown(60);

	const handleClose = () => {
		setOpen(false);
		sessionStorage.setItem(PROMOTION_MODAL_VISIBLE, EBooleanValue.FALSE);
	};
	const handleClick = () => {
		if (promotionUrl) {
			router.push(promotionUrl);
		}
	};

	useEffect(() => {
		const promotionModalVisible = sessionStorage.getItem(PROMOTION_MODAL_VISIBLE);
		if (!promotionModalVisible || promotionModalVisible === EBooleanValue.TRUE) {
			setOpen(true);
			startCountdown();
		}
	}, []);

	useEffect(() => {
		if (countdown === 0) {
			handleClose();
		}
	}, [countdown]);

	return (
		<ModalContainer open={open} onClose={handleClose}>
			<Container>
				<ModalClose onClick={handleClose}>
					<Image
						src="/icon/close.svg"
						alt="close"
						width={12}
						height={12}
						unoptimized
						loader={imageLoader}
					/>
				</ModalClose>
				<PromotionTagImg>
					<TagImage
						src="/image/home/promotion.svg"
						alt="promotion tag"
						width={0}
						height={0}
						unoptimized
						loader={imageLoader}
						priority
					/>
				</PromotionTagImg>
				<Content onClick={handleClick}>
					<Content>
						<picture>
							<source srcSet={promotionImageMobile} media="(max-width: 768px)" />
							<source srcSet={promotionImage} media="(min-width: 769px)" />
							<Image
								src={promotionImage}
								alt="promotion"
								loader={imageLoader}
								width={750}
								height={400}
								loading="lazy"
							/>
						</picture>
					</Content>
				</Content>
			</Container>
		</ModalContainer>
	);
};

export default PromotionModal;
