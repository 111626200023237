/**
 * 页面文本内容
 */

export const DECRIPTION_BOX_TAG = '匠人学院';
/** events page. */
export const EVENTS_PAGE_DESCRIPTION_TITLE = '精彩职场活动 - 澳洲IT求职与面试全攻略';
export const EVENTS_PAGE_DESCRIPTION_DESCRIPTION = [
	'匠人学院已在澳洲各个城市成功举办超200场线上线下活动，涵盖IT求职讲座、Tech女性力量峰会、AI技术主题论坛、简历修改Workshop、Hackson活动、高校新生节等丰富主题。助力求职者结识更多人脉资源、扩大职业社交网络，找到更多志同道合的朋友！加入我们，不仅能提升专业技能，还能结识更多行业大咖，了解澳洲IT行业最新发展趋势和行业资讯，了解澳洲职场文化，尽快融入澳洲生活环境。',
	'加入我们，与600+IT行业顶尖专家深度交流，结识10000+志同道合的澳洲IT圈伙伴，拓展职业人脉，分享行业成功经验和共享行业资源。结识更多在澳洲职场奋斗的华人，和40000+匠人社区成员一起，帮助澳洲华人在获得更多就业机会。'
];

/** create study team */
export const CREATE_STUDY_TEAM_LEVEL_INTRODUCE: StudyTeamLevelIntroduce[] = [
	{
		level: 'Level One',
		suitable: '转专业，转行；时间不多，无自学能力',
		kpi: '无',
		type: '无领导，小组配有一位Tutor',
		features:
			'由一位Tutor带领做项目，可以自由参加和退出；不做考核；不会进入Presentation；无人数限制。'
	},
	{
		level: 'Level Two',
		suitable: '转专业，转行；时间20h/周；有自学能力者；找第一份工作',
		kpi: '最低KPI要求',
		type: '自拟项目',
		features:
			'学生自拟主题和设计项目，由一名Tutor带领，8个程序员+6个DevOps组成；有较高的主动权。'
	},
	{
		level: 'Level Three',
		suitable: 'IT专业背景；有较强自学能力；First Job Seeker；有了第一份工作经验',
		kpi: '最低KPI要求',
		type: '导师项目',
		features:
			'项目大多属于现有项目，技术较深，有一定难度，代码要求高，需要较强自学能力；接近公司工作经验；培养专业人士；人数有限。'
	},
	{
		level: 'Level Four',
		suitable: 'IT专业背景；有较强自学能力；First Job Seeker；有了第一份工作经验',
		kpi: '最低KPI要求',
		type: '商业项目/实习/企业合作项目/德勤合作项目',
		features:
			'涉及技术更广，更新，难度除了Web全栈班基础外额外更多内容学习（Web3，Nextjs，Ts，NFT，智能合约，AWS Lambda等）。代码要求高，需要较强自学能力。接近公司工作经验。培养专业人才。人数有限'
	}
];
export const CREATE_STUDY_TEAM_DEFAULT_RULES = `1. 每个小组成员每周需要贡献不低于18小时的时间，并且每两周至少一个Pull Request，否则从团队离开。
2. 如果小组成员过少，可以解散小组，参加其他更好的小组。
3. 如果不满意当前小组，经过目标组的组长同意后，可以加入别的小组。
4. 所有组员围绕着一个目标，把项目完成上线，为了组也为了自己。
5. 如果组员在组内公然划水，或者影响组内氛围，组长可以直接开除该组员。`;

export const PARTNERS_BOX_DEFAULT_TEXT: IPartnersBoxText = {
	title: '我们的合作公司',
	description:
		'我们与已经和Atlassian、Canva、Amazon、Deloitte等顶级公司达成合作，涵盖多个行业领域，为学员提供更广阔的就业机会和职业发展平台。'
};
